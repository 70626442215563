import { MOVIES } from "./constants";
import focus from "./focus";
import { getValueOf } from "./gridUtils";
import { getIso6391 } from "./iso639/ISO639";
import { SESSION } from "./session";
import { Texts } from "./texts";
import { padZero } from "./utils";

export const printMovieLength = (length: number): string => {
    const h = Math.floor(length / 60);
    const m = length % 60;
    if (h > 0) {
        return `${h}h ${m}m`;
    }
    return `${m}m`;
};

export type AudioLanguages = {
    id: number;
    name: string;
    isMultilanguage: boolean;
    iso6391: string;
    iso6392: string;
    suffix: string;
};
export type SubtitleLanguages = {
    id: number;
    name: string;
    file: string;
    fileExists: boolean;
    fileSuffix: string;
    iso6391: string;
    iso6392: string;
};

export const printLanguages = (languages: AudioLanguages[] | SubtitleLanguages[], texts: { [key: string]: string }) => {
    if (languages.length === 0) {
        return Texts.translateCap(texts, "unavailable");
    }
    return languages.map((lang) => lang.name).join(", ");
};

export enum Gender {
    Male = "male",
    Female = "female",
}

export type movies_Category = {
    id: string;
    name: string;
    items: movies_Group[];
};

export type movies_Group = {
    id: number;
    name: string;
    items: movie[];
};
export type movie = {
    id: number;
    actors: string;
    coverfile: string | null;
    director: string;
    isAdult: boolean;
    languages: AudioLanguages[];
    length: number;
    name: string;
    plot: string;
    price: number;
    priceWithTax: number;
    subtitles: SubtitleLanguages[];
    bannerUrl: string | null;
    bgAsBannerUrl: string | null;
    type: string;
    genderType: Gender;
    icon: string;
    trailerUrl: string | null;
    url: string | null;
    forceAudioUrl: string | null;
    coverUrl: string | null;
    backgroundUrl: string | null;
    priceToShow: number;
    pricesWithTaxes: boolean;
    rented: boolean;
    free2guest: boolean;
    groupId: number;
    groupName: string;
    focusId: string;
    component: any;
    detailComponent: any;
    rentedAt?: string;
    visualizations?: movieVisualizations[];
};
export type movieVisualizations = {
    percentageReached: number;
    startTime: string;
    visualizationTime: number;
};
export type trackInfo = {
    id: number;
    name: string;
    iso: string;
};
export const parseRentedMovies = (categories: any, rentedIds: number[]) => {
    let moviesRented: movie[] = [];
    categories.forEach((category: movies_Category) => {
        category.items.forEach((group: movies_Group) => {
            group.items.forEach((movie: movie) => {
                if (rentedIds.includes(movie.id)) {
                    movie.rented = true;
                    if (moviesRented.filter((movieRented) => movieRented.id === movie.id)?.length === 0) {
                        moviesRented.push({
                            ...movie,
                            groupId: 0,
                            groupName: group.name,
                            focusId: `btn-0_${movie.type}_${movie.id}`,
                        });
                    }
                }
            });
        });
    });
    categories.find((category: movies_Category) => category.id === MOVIES.FILTERS.RENTED).items[0].items = moviesRented;

    return categories;
};

export const printRentaToText = (texts: { [key: string]: string }, orderDate?: string) => {
    const to = orderDate ? new Date(orderDate) : new Date();
    to.setTime(to.getTime() + MOVIES.RENTHOURS * 3600 * 1000);

    const time = `${to.getHours().toString(10).padStart(2, "0")}:${to.getMinutes().toString(10).padStart(2, "0")}`;
    const toDate = `${padZero(to.getDate())}/${padZero(to.getMonth() + 1)}/${to.getFullYear()}`;
    if (orderDate) {
        return Texts.translate(texts, "watch-until").replace("{{time}}", time).replace("{{date}}", toDate);
    }
    return Texts.translate(texts, "movie-rental-success")
        .replace("{{movie}}", SESSION?.cMovie?.name)
        .replace("{{time}}", time)
        .replace("{{date}}", toDate);
};

export const renderHiddenLegendToRow = (group: movies_Group, itemsPerRow: number) => {
    const posFocus = group.items.findIndex((item) => item.focusId === focus.value.current) + 1;
    if (posFocus % itemsPerRow === 0) {
        return;
    } else {
        removeHiddenLegend();
    }
    for (let nextPos = posFocus + 1; nextPos < group.items.length; nextPos++) {
        if (nextPos % itemsPerRow === 0 || nextPos === group.items.length) {
            addHiddenLegend(group.items?.[nextPos - 1]?.focusId);
            break;
        }
    }
};

export const removeHiddenLegend = () => {
    const invisibleLegends: HTMLCollectionOf<Element> = document?.getElementsByClassName("showHiddenLegend");
    if (invisibleLegends.length > 0) {
        for (var i = 0; i < invisibleLegends.length; i++) {
            if (invisibleLegends[i]?.id !== focus.value.current) {
                invisibleLegends[i]?.classList.remove("showHiddenLegend");
            }
        }
    }
};

export const addHiddenLegend = (elementId: string | undefined) => {
    if (!elementId) {
        return;
    }
    document.getElementById(elementId)?.classList.add("showHiddenLegend");
};

export const addBgTransparentImportant = (elementId: string) => {
    const elm: HTMLElement | null = document.getElementById(elementId);
    if (elm) {
        elm.classList.add("bg-transparent");
        elm.style.backgroundImage = "";
    }
};

export const isMovieFullyWatched = (visualizations: movieVisualizations[]) => {
    if (
        !visualizations ||
        visualizations.length === 0 ||
        (visualizations[0] && visualizations[0]?.percentageReached >= 97)
    ) {
        return true;
    }
    return false;
};

export const filterMovieTracks = (
    tracks: trackInfo[],
    movieTracks: AudioLanguages[],
    isMovieForceAudio?: boolean,
): any[] => {
    let filteredTracks: trackInfo[] = [];
    if (isMovieForceAudio) {
        return movieTracks;
    }
    if (!movieTracks) {
        return tracks;
    }

    tracks.forEach((track) => {
        if (movieTracks.find((mTrack) => getIso6391(mTrack.iso6391) === getIso6391(track.iso))) {
            filteredTracks.push(track);
        }
    });

    if (filteredTracks.length === 0) {
        return tracks;
    }
    return filteredTracks;
};

export const setCurrentAudioTrack = (movieTracks: AudioLanguages[]) => {
    const trackGustLang = movieTracks.find((track) => track.iso6391 === localStorage.getItem("lang"));
    if (trackGustLang) {
        SESSION.cAudioTrackId = trackGustLang.id;
        return trackGustLang.iso6391;
    } else {
        SESSION.cAudioTrackId = movieTracks.find((track) => track.iso6391 === "en")?.id || -1;
        return "en";
    }
};

export const SCROLL_ITEMS_DIV = "scrollItemsDiv";

export const scrollItemsDown = (down: boolean, offset: number) => {
    const actualScroll: number = getValueOf(document?.getElementById(SCROLL_ITEMS_DIV)?.style.marginTop || "") || 0;
    const itemHeightAnd = down ? -offset : offset;
    let newScroll = actualScroll + itemHeightAnd;
    if (newScroll > 0) {
        newScroll = 0;
    }
    if (document?.getElementById(SCROLL_ITEMS_DIV)) {
        // @ts-ignore
        document.getElementById(SCROLL_ITEMS_DIV).style.marginTop = newScroll + "px";
    }
};

export const isLastElementOfGroup = (group: movies_Group) => {
    if (!group) {
        return false;
    }
    if (
        parseInt(document?.activeElement?.getAttribute("customattribute") || "0") ===
        group?.items?.[group.items.length - 1]?.id
    ) {
        return true;
    }
    return false;
};
