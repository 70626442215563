import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MOVIES, PERMISSION } from "../../utils/constants";
import { setNumberPress } from "../../actions/eventsActions";
import { eventHandler } from "../../utils/eventHandler";
import Limbo from "../../components/screen/limbo";
import VerticalCenter from "../../components/common/verticalCenter";
import { Texts } from "../../utils/texts";
import { STB } from "../../hooks/apis/stb";
import focus from "../../utils/focus";
import Grid from "../grid/Grid";
import { getMoviesData } from "../../hooks/GraphqlCalls/movies/moviesData";
import { getStayGuestRoomId } from "../../utils/utils";
import { getRentedMovies } from "../../hooks/GraphqlCalls/movies/getRentedMovies";
import { SESSION } from "../../utils/session";
import { parseRentedMovies } from "../../utils/movies";

const AdultMovies = () => {
    const dispatch = useDispatch();
    const texts = useSelector((state) => state.ui.texts);
    // ADULT movies are not free 2 guest
    const FREE2GUEST = false;
    const { sessionData } = useSelector((state) => state.status);
    const guestCountry = useSelector(
        (state) => state.status.locationData.guestInfo.country || state.status.locationData.projectCountryRef,
    );
    const projectLanguageCode = useSelector(
        (state) => state.status.locationData.projectLangs.find((x) => x.isDefault === true).languageRef,
    );

    const stayGuestRoomId = getStayGuestRoomId(sessionData);

    const [loadingMovies, setLoadingMovies] = useState(true);
    const [moviesData, setMoviesData] = useState(null);
    const [rentedData, setRentedData] = useState(null);
    const [parsedData, setParsedData] = useState(null);
    const [filter, setFilter] = useState(MOVIES.FILTERS.ALL_MOVIES);

    const langCode = localStorage.getItem("lang") || projectLanguageCode;

    useEffect(() => {
        const onSuccessMoviesData = (movies) => {
            parseMoviesResponse(movies);
        };
        getMoviesData(true, setMoviesData, { guestCountry, texts, projectLanguageCode });
        getRentedMovies({ stayGuestRoomId, last24h: FREE2GUEST }, setRentedData);
    }, []);

    useEffect(() => {
        if (moviesData && rentedData) {
            setLoadingMovies(false);
            setParsedData(parseRentedMovies(moviesData, rentedData));
        } else {
            // timeout for error in query or null data
            setTimeout(() => {
                setLoadingMovies(false);
            }, 6000);
        }
    }, [moviesData, rentedData]);

    useEffect(() => {
        if (moviesData?.length > 0 && moviesData[0]?.items.length > 0) {
            // FOCUS first movie of first group from first category
            focus.value.replace(`btn-${moviesData[0].items[0].id}_movie_${moviesData[0].items[0].items[0].id}`);
        }
    }, [moviesData]);

    const parseMoviesResponse = (movies) => {
        console.log(movies);
        setLoadingMovies(false);
        if (!movies) {
            return;
        }

        setMoviesData(movies);
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;
        if (keyData) {
            switch (keyData.value) {
                default:
                    break;
            }
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            id="adult-movies"
            className={"w-full h-full"}
            style={{
                visibility: "visible",
            }}
            onKeyDown={(e) => {
                onkeydown(e);
            }}
        >
            <Limbo id="movies-limbo" noBlocker={true} />

            {parsedData && parsedData.length > 0 ? (
                <Grid
                    gridData={parsedData}
                    loadingData={loadingMovies}
                    firstFilter={parsedData[0].id}
                    itemHeight={305}
                    options={{
                        backgroundBanner: false,
                        infoOfFocus: false,
                        noGradient: true,
                        focusType: "_movie_",
                        singleGroupItemsPerRow: 8,
                    }}
                />
            ) : (
                <VerticalCenter
                    text={Texts.capitalize(
                        loadingMovies ? `${texts["loading"]}...` : texts["no data available"],
                    ).replace("{{data}}", texts["movies"])}
                    cClass={`white text-4xl`}
                />
            )}
        </div>
    );
};
export default AdultMovies;
