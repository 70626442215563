import { setFontsReady } from "../../../actions/statusActions";
import { PERMISSION, TV_MODELS } from "../../../utils/constants";
import { prepareDeviceKeyMap, resetCredentialsOnPowerOff } from "../../../utils/utils";
import { Media } from "../media";
import { idcap } from "./idcap";
import { keyMap } from "./keyMap";
import { procentric } from "./procentric";

export const STB = {
    model: TV_MODELS.LG,
    ref: "",
    supportVideoLoop: true,
    alarmVolume: 16,
    supportBlur: false,
    init() {
        this.keyMap = prepareDeviceKeyMap(keyMap);
        procentric.init();
    },
    initializeCastServices(castingGatewayIP, airserverAccountCode) {
        sessionStorage.setItem("castingGatewayIP", castingGatewayIP);
        sessionStorage.setItem("airserverAccountCode", airserverAccountCode);
        procentric.registerAirserver();
        procentric.prepareUDPCommunication();
    },
    checkCCBuiltIn(model) {
        const CCBuiltInModles = ["US662H", "UR762H", "STB-6500", "UM767H", "WM960H", "AM960H"];
        CCBuiltInModles.forEach((ccmodel) => {
            if (model.indexOf(ccmodel) > -1) {
                STB.builtInCC = true;
                STB.navigateOnCCInstructions = true;
                sessionStorage.setItem("namePrefix", "AirServer");
                localStorage.setItem("builtInCC", true);
            }
        });
    },
    getMac(onSuccess) {
        const onMacSuccess = (s) => {
            this.ip = s.ip_address;
            if (s.ethernet_plugged) {
                procentric.getProperty("mac_address", (value) => {
                    this.mac = value;
                    this.ref = `${STB.model}-${value}`;
                    if (onSuccess) {
                        onSuccess();
                    }
                });
            } else if (s.wifi_plugged) {
                procentric.getDeviceMac((value) => {
                    this.mac = value;
                    this.ref = `${STB.model}-${value}`;
                    if (onSuccess) {
                        onSuccess();
                    }
                });
            }
        };
        procentric.getNetworkInformation(onMacSuccess);
    },
    powerOn() {
        procentric.poweron();
    },
    powerOff() {
        procentric.poweroff();
    },
    _gotoOfflineMode() {
        document.location.href = "http://127.0.0.1:8051/as/app/1/3///ha.html";
    },

    updatePowerState() {
        procentric.getStatus();
    },
    setVolume(volume) {
        procentric.setVolumeLevel(volume);
    },
    changeToInput(input) {
        //to ensure video size
        setTimeout(() => {
            Media.setVideoSize(0, 0, 100, 100);
            procentric.setHDMI(input);
        }, 200);
    },
    backToInterface() {
        this.reload();
    },
    saveDataForHA(localKey, dataToStore) {
        const urlServerSaveDataForHA = "http://procentric.local:2222/gridRepository/saveGrid.php";

        const keysToStore = ["connectionRdy", "continueWatching", "grid", "highAvailability"];

        let dataForHA = JSON.parse(localStorage.getItem("dataForHA"));
        if (!dataForHA) {
            dataForHA = {};
        }

        dataForHA[localKey] = dataToStore;
        localStorage.setItem("dataForHA", JSON.stringify(dataForHA));
        let _keys_rdy = 0;
        Object.keys(dataForHA).forEach((element) => {
            if (keysToStore.includes(element)) {
                _keys_rdy++;
            }
        });

        if (keysToStore.length === _keys_rdy) {
            // Send data to server to be saved
            const arrangeData = {
                grid: dataForHA.grid,
                texts: { continueWatching: dataForHA.continueWatching, connectionRdy: dataForHA.connectionRdy },
                highAvailability: dataForHA.highAvailability,
            };
            fetch(urlServerSaveDataForHA, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                body: "mac=" + this.mac + "&data=" + encodeURIComponent(JSON.stringify(arrangeData)),
            });
            localStorage.removeItem("dataForHA");
        } else if (localKey === PERMISSION.HIGH_AVAILABILITY && !dataForHA.highAvailability) {
            // force send HA NO permission to be saved
            const arrangeData = {
                grid: { grid: [{ id: "no_grid" }] },
                texts: { continueWatching: null, connectionRdy: null },
                highAvailability: dataForHA.highAvailability,
            };

            fetch(urlServerSaveDataForHA, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                },
                body: "mac=" + this.mac + "&data=" + encodeURIComponent(JSON.stringify(arrangeData)),
            });
        }
    },
    checkOnLine() {
        const onNetInformation = (s) => {
            if (!s.internet_available) {
                STB.gotoOfflineMode();
            }
        };
        procentric.getNetworkInformation(onNetInformation);
    },
    launchBluetooth() {
        procentric.appOpenById(procentric.APP_BLUETOOTH_ID);
    },
    changeTVName(name) {
        if (name) {
            this.tvName = name;
            procentric.setProperty("tv_name", name);
        }
    },
    _checkout() {
        procentric.checkOut();
    },
    _launchApp(appName) {
        switch (appName) {
            case "Netflix":
            case "NETFLIX":
                procentric.appOpenById(procentric.APP_NETFLIX_ID);
                break;
            case "YouTube":
            case "YOUTUBE":
                procentric.appOpenById(procentric.APP_YOUTUBE_ID);
                break;
            default:
                break;
        }
    },
    resetCredentials() {
        var onCheckout = function () {
            localStorage.setItem("reset_credentials_success", true);
            procentric.reboot();
        };
        procentric.checkOut(onCheckout);
    },
    _resetCredentialsOnPowerOff() {
        resetCredentialsOnPowerOff();
    },
};
