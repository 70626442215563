import { navigate } from "wouter/use-location";
import { STB } from "../hooks/apis/stb";
import { KEYS } from "./keys";
import { PATHS } from "./constants";
import { EVENTS } from "./eventsConst";

export const eventHandler = {
    focusedBtn: null,
    previousPath: null,
    locationHistory: [],
    keysHistory: [],
    eventsBlocked: false,

    setPreviousPath(path) {
        this.previousPath = path;
    },

    skipNextPath() {
        this.skipPath = true;
    },
    cleanHistoryDuplicates() {
        let newHistory = [];
        for (let index = 0; index < this.locationHistory.length; index++) {
            if (
                index + 1 < this.locationHistory.length &&
                this.locationHistory[index] === this.locationHistory[index + 1]
            ) {
                continue;
            }
            newHistory.push(this.locationHistory[index]);
        }
        this.locationHistory = newHistory;
    },
    locationStack(path) {
        if (this.skipPath) {
            this.skipPath = false;
            return;
        }
        if (path === PATHS.HOME) {
            this.locationHistory = [path];
        } else {
            this.locationHistory.push(path);
        }
        //console.log(this.locationHistory);
    },
    locationGoBack(path) {
        this.locationHistory.pop();
        //console.log(this.locationHistory);

        this.skipNextPath();
        navigate(this.locationHistory?.[this.locationHistory?.length - 1] || PATHS.HOME);
    },
    setFocusedBtn(btn) {
        if (!btn?.id) {
            return;
        }
        //console.log("SET FOCUS TO " + btn?.id);
        this.focusedBtn = btn;
        window.focusedBtn = btn;
    },

    blockMovement(time) {
        this.eventsBlocked = true;
        clearTimeout(this.blockMovementTimeout);
        this.blockMovementTimeout = setTimeout(
            function () {
                this.eventsBlocked = false;
            }.bind(this),
            time,
        );
    },

    stopPropagation(e) {
        e.preventDefault();
        e.stopPropagation();
    },
    getKeyData(e) {
        let code = e.keyCode ? e.keyCode : e.which;
        let eventDetail, eventval, keyStatus;

        if (this.keysHistory?.[0]?.timeStamp != e.timeStamp && this.eventsBlocked) {
            console.log("key event blocked");
            this.stopPropagation(e);
            return null;
        }

        if (!code && e.detail) {
            eventDetail = e.detail;
            eventval = eventDetail.split(",");
            keyStatus = parseInt(eventval[1]);

            code = parseInt(eventval[0]);
        } else if (!code && e.key_code) {
            code = e.key_code;
            keyStatus = 2;
        }
        if (!code) {
            code = eventHandler.lastKeyPressCode;
        }
        if (code == 457) {
            var key = webapis.tvinfo.TvInfoMenuKey.CAPTION_BG_COLOR_KEY;
            var value = 4;
            var onsuccess = function () {};
            var onError = function (error) {
                console.log("Problem occurred ." + JSON.stringify(error));
            };
            // webapis.avplay.setStreamingProperty("ENABLE_TTX",true);
            webapis.tvinfo.setMenuValue(key, value, onsuccess, onError);
        }

        let keyData = STB.keyMap.filter((key) => parseInt(key.code) === parseInt(code));
        if (sessionStorage.getItem("outOfInterface") === "true" && keyData[0].value !== KEYS.home) {
            return;
        }
        if (keyData?.[0]) {
            var keyInfo = {
                code,
                value: keyData[0].value,
                type: keyData[0].type,
                time: new Date(),
                timeStamp: e.timeStamp,
            };
            this.checkZafiroShortcut(keyInfo);
            return keyInfo;
        }
        return null;
    },
    checkZafiroShortcut(keyInfo) {
        //key propagated and already stored in history
        if (this.keysHistory?.[0]?.timeStamp == keyInfo.timeStamp) {
            return;
        }
        this.keysHistory.unshift(keyInfo);
        if (this.keysHistory.length > 10) {
            this.keysHistory = this.keysHistory.slice(0, -1);
        }

        if (
            this.keysHistory?.length > 5 &&
            this.keysHistory[0]?.value === "down" &&
            this.keysHistory[1]?.value === "up" &&
            this.keysHistory[2]?.value === "down" &&
            this.keysHistory[3]?.value === "down" &&
            this.keysHistory[4]?.value === "up" &&
            this.keysHistory[5]?.value === "up"
        ) {
            if (new Date(this.keysHistory[0].time).getTime() - new Date(this.keysHistory[3].time).getTime() < 2000) {
                let e = new CustomEvent(EVENTS.type.POPUP, {
                    detail: { type: EVENTS.POPUP.ZAFIRO },
                });
                document.dispatchEvent(e);
            }
        }
    },
};
