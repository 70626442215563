/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useLayoutEffect, useRef, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { createElement, memo } from "preact/compat";
import { Theme } from "../utils/theme";
import { printMenuWidget } from "../utils/screenUtils";
import { displayMenu, setMenuLastFocus } from "../actions/uiActions";
import { MENU_TYPES } from "../utils/constants";
import focus from "../utils/focus";
import { cloneObject, createObject, focusOnLimbo, getEnvVar, inHomePage } from "../utils/utils";
import { Media } from "../hooks/apis/media";
import { eventHandler } from "../utils/eventHandler";
import { KEYS } from "../utils/keys";
import { ScreenKeyControl } from "../hooks/customKeyControl/screen-keycontrol";
import { useLocation } from "wouter";

const Menu = () => {
    const [location] = useLocation();
    const dispatch = useDispatch();
    const TIME_TO_SHOW_MENU = 5000;

    // //Store
    const { showMenu, menu, landingPage } = useSelector((state) => state.ui);
    const tags = useSelector((state) => state.status.locationData.tags);
    const sessionData = useSelector((state) => state.status.sessionData);
    const unreadMessages = useSelector((state) => state.status.unreadMessages);
    const alarm = useSelector((state) => state.status.sessionData.alarm);

    const activeMenuBtnId = useSelector((state) => state.events.activeMenuBtnId);
    const flagGetWidgetPos = useSelector((state) => state.ui.flagGetWidgetPos);
    const popup = useSelector((state) => state.ui.popup);
    const [focusOnLoad, setFocusOnLoad] = useState();

    const isHiddenMenu = menu?.temporaryType === MENU_TYPES.HIDDEN || menu?.type === MENU_TYPES.HIDDEN;

    let unstackFocusOnExist = useRef();
    let focusOnLoadRef = useRef();
    focusOnLoadRef.current = focusOnLoad;
    let menuLastFocus = useRef();
    menuLastFocus.current = focus.value.current;

    let popupRef = useRef();
    popupRef.current = popup;

    let displayMenuTimeout = useRef();

    let menuRef = useRef();
    menuRef.current = menu;

    if (!menu || !landingPage) {
        return;
    }
    let MENUCONTENT = {
        contentStyle: JSON.parse(landingPage.contentStyle || null),
        contentWidgets: [menu.content],
        devices: cloneObject(landingPage.devices),
        pos: JSON.stringify({ TV: [{ ...menu.pos }] }),
    };
    if (MENUCONTENT.contentStyle) {
        const menuContentData = JSON.parse(menu.content.data);
        MENUCONTENT.contentStyle.itemsMargin = menuContentData.itemsMargin;
        MENUCONTENT.contentStyle.margin = menuContentData.margin;
    }
    if (!window.MenuRender) {
        // eslint-disable-next-line no-undef
        window.MenuRender = createObject(Render);

        window.MenuRender.init(
            localStorage.getItem("lang"),
            MENUCONTENT,
            Theme.config,
            tags,
            Theme.logos,
            null,
            menu.menuColorsAndAnimations,
        );
        window.MenuRender.dasURL = `${getEnvVar("API")}das/`;
        window.MenuRender.castURL = tags?.find((tag) => tag.tag === "pairingurl")?.value;
    }

    const arrayWidgets = [];

    const widgetRendered = window.MenuRender.widget(menu.content, null, { isMenu: true });
    widgetRendered.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.5)";

    const divMenu = createElement("div", {
        dangerouslySetInnerHTML: {
            __html: widgetRendered.outerHTML,
        },
    });
    arrayWidgets.push(divMenu);
    menu?.content?.contentWidgets.forEach((_widget) => {
        printMenuWidget({
            widgetItems: arrayWidgets,
            widget: _widget,
            container: menu.content,
            unreadMessages,
            alarm,
            useWidgetRender: window.MenuRender,
        });
    });
    useEffect(() => {
        const exist = document.getElementById(focusOnLoadRef.current);
        if (exist && showMenu && menu.type === MENU_TYPES.FIXED && inHomePage()) {
            let widgetToFocus = window.MenuRender.getFirstWidgetToFocus(sessionData, true);
            focus.value.replace(widgetToFocus);
        }
    }, [location]);

    const widgetContainer = createElement("div", {}, [arrayWidgets]);

    useEffect(() => {
        Media.showVideoOnBack();
        if (menu.lastFocus) {
            setFocusOnLoad(menu.lastFocus);
        } else if (showMenu && activeMenuBtnId) {
            setFocusOnLoad(activeMenuBtnId);
        } else {
            let widgetToFocus = window.MenuRender.getFirstWidgetToFocus(sessionData, true);
            if (!focusOnLoad && widgetToFocus) {
                dispatch(setMenuLastFocus(widgetToFocus));
                setFocusOnLoad(widgetToFocus);
                focusOnLoadRef.current = widgetToFocus;
            }
        }
        startTimeout();
        return () => {
            if (unstackFocusOnExist.current) {
                //si se muestra un popup hacemos el unstack antes de mostrarlo,
                //si no por orden de carga perdíamos el foco
                if (!popupRef.current && !eventHandler.preventMenuUnstack) {
                    focus.value.unstack();
                }
            }
            clearTimeout(displayMenuTimeout.current);
            if (!popupRef.current) {
                Media.showVideoOnFront();
            }
            eventHandler.preventMenuUnstack = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (popup && menu && isHiddenMenu) {
            dispatch(displayMenu(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popup]);

    useLayoutEffect(() => {
        const exist = document.getElementById(focusOnLoadRef.current);
        if (
            exist &&
            showMenu &&
            (menu.type !== MENU_TYPES.FIXED || (menu.temporaryType && menu.temporaryType === MENU_TYPES.HIDDEN))
        ) {
            focus.value.stack(focusOnLoadRef.current);
            unstackFocusOnExist.current = true;
        }
        if (exist && showMenu && menu.type === MENU_TYPES.FIXED && inHomePage()) {
            focus.value.replace(focusOnLoadRef.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusOnLoadRef.current]);

    useEffect(() => {
        if (isHiddenMenu) {
            startTimeout();
        } else if (!menu.temporaryType && menu.type === MENU_TYPES.FIXED) {
            clearTimeout(displayMenuTimeout.current);
            displayMenuTimeout.current = null;
            dispatch(displayMenu(menu.type === MENU_TYPES.FIXED));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu.temporaryType]);

    const startTimeout = () => {
        if (isHiddenMenu) {
            clearTimeout(displayMenuTimeout.current);
            displayMenuTimeout.current = setTimeout(() => {
                dispatch(setMenuLastFocus(menuLastFocus.current));
                dispatch(displayMenu(false));
            }, TIME_TO_SHOW_MENU);
        }
    };

    const menuKeyDown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = true;
        if (keyData?.type === "arrow" && !focusOnLimbo()) {
            startTimeout();
            ScreenKeyControl({
                direction: keyData.value,
                dispatch,
                sessionData,
                menu: { ...menuRef.current, showing: showMenu },
                flagGetWidgetPos,
                activeMenuBtnId,
            });
        } else if (keyData?.type === "arrow" && focusOnLimbo()) {
            preventDefault = false;
        } else if (keyData) {
            switch (keyData.value) {
                case KEYS.home:
                    preventDefault = false;
                    break;
                case KEYS.back:
                    if (
                        menuRef.current.temporaryType === MENU_TYPES.HIDDEN ||
                        menuRef.current.type === MENU_TYPES.HIDDEN
                    ) {
                        clearTimeout(displayMenuTimeout.current);
                        dispatch(displayMenu(false));
                    } else {
                        preventDefault = false;
                    }
                    break;

                default:
                    preventDefault = false;
                    startTimeout();
                    break;
            }
        }
        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <>
            <div
                className="fixed w-full top-0 left-0"
                style={{ position: "absolute", zIndex: 500, visibility: "visible" }}
                onKeyDown={(e) => {
                    menuKeyDown(e);
                }}
            >
                {widgetContainer}
            </div>
            {menu && (menu.type === MENU_TYPES.HIDDEN || menu.temporaryType === MENU_TYPES.HIDDEN) ? (
                <div
                    className="top-0 left-0 w-full h-full z-101"
                    style={{ position: "absolute", backgroundColor: "rgba(10,10,0,0.2)" }}
                />
            ) : null}
        </>
    );
};

export default memo(Menu);
