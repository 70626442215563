/* eslint-disable no-undef */
import { writeLog } from "../../../../utils/utils";
import { Media } from "./media";
import { STB } from "./stb";

let localOfflinePath = "/mtd_down/common/HospitalityBrowser/";
let localSubtitlePath = `${localOfflinePath}subtitles/`;
let remoteSamsungPath = "http://app.srv.entertainment-solutions.eu/samsung/";
let remoteIngesuitePath = "http://app.srv.entertainment-solutions.eu/";

if (typeof session != "undefined" && session.serverprops.cloudIp) {
    remoteSamsungPath = `http://${session.serverprops.cloudIp}/samsung/`;
    remoteIngesuitePath = `http://${session.serverprops.cloudIp}/`;
}

function pausecomp(ms) {
    ms += new Date().getTime();
    while (new Date() < ms) {}
}

/**
 * This file holds the functions to manage samsung TVs.
 * This file is used by both, dtools/dtests/samsung, and ingesuite
 * STB, and Media APIs. This way we have a common code base for testing
 * and production environments.
 */
export const samsung = {
    hdmi1Code: 31, //31 The HDMI1 source
    hdmi2Code: 32, //32 The HDMI2 source
    hdmi3Code: 33, //33 The HDMI3 source
    hdmi4Code: 34, //34 The HDMI4 source
    av1Code: 15, //15 The AV1 source

    api: {
        widget: {},
        plugin: {},
        tvKey: {},
    },

    init() {
        samsung.api.widget = new Common.API.Widget();
        samsung.api.plugin = new Common.API.Plugin();
        samsung.api.tvKey = new Common.API.TVKeyValue();

        //evita que al sintonizar canales dvb aparezca el display del canal sintonizado
        //samsung.hideOsd();
    },

    getFirmware() {
        let pluginSef = document.getElementById("sefDevice");
        pluginSef.Open("Device", "1.000", "Device");
        return pluginSef.Execute("Firmware");
    },
    getModel() {
        let pluginSef = document.getElementById("sefDevice");
        pluginSef.Open("Device", "1.000", "Device");
        return pluginSef.Execute("GetModel");
    },

    enableKeyboard() {
        let pluginSef = document.getElementById("pluginObjectNNavi");
        pluginSef.Open("NNavi", "1.000", "NNavi");
        pluginSef.Execute("SetGrabKeyboard", "1", "0");
    },
    disableKeyboard() {
        let pluginSef = document.getElementById("pluginObjectNNavi");
        pluginSef.Open("NNavi", "1.000", "NNavi");
        pluginSef.Execute("SetGrabKeyboard", "0", "0");
    },

    getVolume() {
        let volPlug = document.getElementById("sefAudio");
        volPlug.Open("Audio", "1.000", "Audio");
        return Number(volPlug.Execute("GetVolume"));
    },

    setVolume(volume) {
        if (volume == 0) {
            this.previousVol = this.getVolume();
        }
        let volPlug = document.getElementById("sefAudio");
        volPlug.Open("Audio", "1.000", "Audio");
        volPlug.Execute("SetVolume", volume);
        /*if(volume==0){
				volPlug.Execute("SetUserMute",1);
			}			
			else{
				
				volPlug.Execute("SetUserMute",0);
			}*/
        STB.showVolume(volume);
    },

    getPowerState() {
        return sefTV.Execute("GetPowerState");
    },

    setPowerOn() {
        console.debug("SET POWER ON");
        //This API only supports in 2014 and above Hotel TV.
        sefHotel.Execute("SetPowerOn");
    },
    setPowerOff() {
        console.debug("SET POWER OFF");
        //This API only supports in 2014 and above Hotel TV.
        sefHotel.Execute("SetPowerOff");
    },
    hideOsd() {
        let NNaviPlugin = document.getElementById("siNNavi");
        NNaviPlugin.SetBannerState(1);
    },

    deleteSubtitlesDirOnTv() {
        console.debug("DELETE SUB FOLDER ON TV");
        sefFiles.Open("FileSystem", "1.000", "FileSystem");
        sefFiles.Execute("SetWidgetInfo", 2, localOfflinePath);
        let files = sefFiles.Execute("GetListFiles", localSubtitlePath);
        files = JSON.parse(files);
        for (i = 0; i < files.length; i++) {
            sefFiles.Execute("Delete", localSubtitlePath + files[i]);
            console.debug(`Deleting subtitle: ${localSubtitlePath}${files[i]}`);
        }
    },

    network: {
        getApSSID() {
            if (!sefNetwork) {
                return;
            }

            apSSID = sefNetwork.Execute("GetSoftAPSSID");
            return apSSID;
        },
        getApKey() {
            if (!sefNetwork) {
                return;
            }

            apKey = sefNetwork.Execute("GetSoftAPSecurityKey");
            return apKey;
        },
        autogenerateApKey(value) {
            if (!sefNetwork) {
                return;
            }

            apKey = sefNetwork.Execute("SetSoftAPSecurityKeyAutoGeneration", value);
            return apKey;
        },
        isApEnabled() {
            if (!sefNetwork) {
                return;
            }

            apEnabled = sefNetwork.Execute("IsSoftAPEnabled");
            return apEnabled;
        },
        enableAp(params) {
            if (!sefNetwork) {
                return;
            }

            apEnabled = sefNetwork.Execute("EnableSoftAP");
            //set ap name
            sefNetwork.Execute("SetTVName", params && params.name ? params.name : session.clientprops.roomname);
            if (params && params.channel) {
                sefNetwork.Execute("SetSoftAPChannel", params.channel);
            }
            if (params && params.strength) {
                sefNetwork.Execute("SetSoftAPSignalStrength", params.strength);
            }

            return apEnabled;
        },
        disableAp() {
            if (!sefNetwork) {
                return;
            }

            apDisabled = sefNetwork.Execute("DisableSoftAP");
            return apDisabled;
        },
    },

    /**
     * Video control section.
     * Requires sefIPTV and sefPlayer objects.
     */
    video: {
        // Video Panel screen size
        vpsw: 960, //width
        vpsh: 540, //height

        // dimension factor
        // please note, it may vary among TV models!
        iptvhf: 2, //horizontal factor
        iptvvf: 2, //vertical factor

        // IPTV Panel screen size
        iptvsw: this ? this.vpsw * this.iptvhf : null, //width
        iptvsh: this ? this.vpsh * this.iptvvf : null, //height

        //If it is using the player or the IPTV API
        isPlayer: false,
        isPlaying: false,

        //If it is using the dvbt player
        isDVBT: false,

        //Saving previous coordinates
        sx: 0,
        sy: 0,
        sw: this ? this.iptvsw : null,
        sh: this ? this.iptvsh : null,

        ctime: 0,
        cspeed: 1,
        isPaused: false,

        /**
         * Initializes IPTV and Player SEF objects.
         * Also stops whatever is being played.
         */
        init() {
            this.iptvsw = this.vpsw * this.iptvhf; //width
            this.iptvsh = this.vpsh * this.iptvvf; //height
            this.sw = this.iptvsw;
            this.sh = this.iptvsh;

            //First, check if the objects are inside the HTML HEAD tag.
            let abort = false;
            if (typeof sefIPTV == "undefined") {
                abort = true;
                console.error("sefIPTV object is not created!");
            }
            if (typeof sefPlayer == "undefined") {
                abort = true;
                console.error("sefPlayer object is not created!");
            }
            if (abort) {
                return;
            }

            //Initialize IPTV plugin
            try {
                sefIPTV.Open("IPTV", "1.000", "IPTV");
                sefIPTV.OnEvent = this.onIPTVEvent;
            } catch (e) {
                console.error(`Error initializing IPTV SEF Plugin: ${e.message}`, e);
            }

            //Initialize Player plugin
            try {
                sefPlayer.Open("Player", "1.000", "Player");
                sefPlayer.OnEvent = this.onPlayerEvent;
            } catch (e) {
                console.error(`Error initializing Player SEF Plugin: ${e.message}`, e);
            }

            //Initialize Window plugin
            try {
                sefWindow.Open("Window", "1.000", "Window");
                sefWindow.OnEvent = this.onWindowEvent;
            } catch (e) {
                console.error(`Error initializing Player SEF Plugin: ${e.message}`, e);
            }

            //Initialize DVB plugin
            try {
                sefDVB.Open("Window", "1.000", "Window");
            } catch (e) {
                console.error(`Error initializing DVB SEF Plugin: ${e.message}`, e);
            }

            //Initialize TV plugin
            try {
                sefTV.Open("TV", "1.000", "TV");
                sefTV.OnEvent = this.onTVEvent;
                // var PL_TV_EVENT_CHANGE_POWER_STATE = 211;
                sefTV.Execute("SetEvent", 211);
            } catch (e) {
                console.error(`Error initializing Player SEF Plugin: ${e.message}`, e);
            }

            //Initialize Network plugin
            try {
                sefNetwork.Open("Network", "1.000", "Network");
                sefNetwork.OnEvent = this.onNetworkEvent;
            } catch (e) {
                console.error(`Error initializing Network SEF Plugin: ${e.message}`, e);
            }
            //Initialize Network plugin
            try {
                sefMirroring.Open("TaskManager", "1.000", "TaskManager");
            } catch (e) {
                console.error(`Error initializing Mirroring SEF Plugin: ${e.message}`, e);
            }
            //Initialize Hotel plugin
            try {
                sefHotel.Open("HOTEL", "1.000", "HOTEL");
            } catch (e) {
                console.error(`Error initializing Hotel SEF Plugin: ${e.message}`, e);
            }

            //Stop anything that might be playing...
            this.stop();
            console.info("Samsung video initialized! :D");
        },

        onIPTVEvent(event, d1, d2) {
            console.log(`iptv event: ${event}, ${d1}, ${d2}`);
            if (event == 2 && d1 == 14) {
                //Solving bug...
                samsung.video.resize(samsung.video.sx, samsung.video.sy, samsung.video.sw, samsung.video.sh);
            }

            if (event == 2 && d1 == 0) {
                setTimeout(() => {
                    //if the TV is powered off playing TV, the interface is reloaded
                    //this reload is done, because when tv is playing in full screen, and the TV is powered off,
                    //next time the TV is on, the screen is black due to the full screen mode without video
                    //if (samsung.getPowerState()==0)
                    //STB.reloadInterface();
                }, 1000);
            }

            //set guest language
            if (event == 2 && d1 == 7) {
                //////////////
                //Sin estos resizes el video no se muestra en la pos correcta
                const x = samsung.video.sx;
                const y = samsung.video.sy;
                const w = samsung.video.sw;
                const h = samsung.video.sh;
                if (w != 100 && h != 100) {
                    samsung.video.resize(0, 0, 100, 100);
                    samsung.video.resize(x, y, w, h);
                } else {
                    samsung.video.resize(0, 0, 100, 100);
                }
                //////////////
                /*  var tracks = Media.Video.getAudioList();

                for (var i = 0; i < tracks.length; i++) {
                    var lang = tracks[i];
                    if (
                        lang == session.clientprops.lang_iso6391 ||
                        lang == session.clientprops.lang_iso6392
                    ) {
                        console.debug("Setting guest language");
                        Media.Video.changeAudio(i, { iso6392: lang });
                        break;
                    }
                }*/
            }
        },

        onPlayerEvent(event, d1, d2) {},

        replayHLSChannel() {},

        onWindowEvent(event, d1, d2) {
            console.debug(`window event: ${event}, ${d1}, ${d2}`);
        },

        onTVEvent(event, d1, d2) {
            //console.debug("tv event: " + event + ", " + d1 +", "+d2);
            if (d1 == 211 && d2.indexOf("param1:6") > -1) {
                //STB.reload();
            } else if (d1 == 211 && d2.indexOf("param1:7") > -1) {
                Media.replayCurrentVideo();
            }
        },

        onNetworkEvent(event, d1, d2) {
            // if Ethernet Connection is Disconnect => load High Availability
            if (event == 0 && d1 == 0) {
                STB.toHighAvailability();
            }
            console.debug(`Network event: ${event}, ${d1}, ${d2}`);
        },

        /**
         * Plays a TV channel from a multicast stream
         * @param ip
         * @param port
         */
        tune(ip, port) {
            if (this.isPlayer) {
                this.stop();
                this.isPlayer = false;
            }
            if (this.isDVBT) {
                this.isDVBT = false;
            }
            try {
                this.isPlaying = true;
                if (sefIPTV) {
                    sefIPTV.Execute("SetTuneURL", `rtp://${ip}:${port}|HW`);
                }
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },

        //param in vw and vh
        resize(x, y, w, h) {
            writeLog(`Resize: ${x},${y},${w},${h}`);
            this.sx = x;
            this.sy = y;
            this.sw = w;
            this.sh = h;

            try {
                if (samsung.video.isPlayer) {
                    // media player uses a different coordinate space
                    x = Math.floor(x / this.iptvhf);
                    y = Math.floor(y / this.iptvvf);
                    w = Math.floor(w / this.iptvhf);
                    h = Math.floor(h / this.iptvvf);
                    console.debug(`=== Calling resize as player: ${x},${y},${w},${h}`);
                    //sefPlayer.Open("Player", "1.000", "Player");
                    if (sefPlayer) {
                        sefPlayer.Execute("SetDisplayArea", x, y, w, h);
                    }
                    if (sefWindow) {
                        sefWindow.Execute("SetScreenRect", x, y, w, h);
                    }
                } else {
                    console.debug(`=== Calling resize as IPTV: ${x},${y},${w},${h}`);

                    //We save this in variables, to solve a bug. Check this.onIPTVEvent();
                    const xPos = Math.floor((this.vpsw / 100) * x);
                    const yPos = Math.floor((this.vpsh / 100) * y);
                    const wPos = Math.floor((this.vpsw / 100) * w);
                    const hPos = Math.floor((this.vpsh / 100) * h);
                    sefDVB.Open("Window", "1.000", "Window");
                    sefDVB.Execute("SetScreenRect", xPos, yPos, wPos, hPos);
                }
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }

            //If resized while paused, it unpauses
            //But trying to solve it like this, makes the TV enter in an
            //infinite loop or something :S
            //if(this.isPaused){
            //	this.pause();
            //}
        },

        /**
         * Plays something with the Player API.
         * URLs cant take the following forms:
         *     - ....
         * Examples:
         * rtsp://10.1.1.53/TheBourneLegacy_multi.ts
         * http://10.1.1.1/enric/philips/tests/iceagecd.mp4
         * http://www.tv3.cat/directetv3cat/tv3catpc.m3u8|COMPONENT=HLS
         *
         * @param url The URL with what you want to play.
         */
        play(url) {
            samsung.video.stop();
            this.isPlayer = true;
            this.isDVBT = false;
            this.ctime = 0;
            //TODO
            //url ='http://movieshls.srv.entertainment-solutions.eu/movie-id-597-lang-es|COMPONENT=HLS';
            //url ='http://10.1.1.15:8080/trailer-id616.m3u8|COMPONENT=HLS';
            //url ='http://www.tv3.cat/directetv3cat/tv3catpc.m3u8|COMPONENT=HLS';
            this.isPaused = false;
            try {
                if (!Media.Audio.playingAlarm) {
                    this.isPlaying = true;
                }
                sefPlayer.Execute("InitPlayer", url);
                sefPlayer.Execute("StartPlayback", 0);
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },

        playDVBT(frecuency, bw, isc, ptc, symbolrate, modulationtype) {
            this.isDVBT = true;
            this.isPlayer = false;
            this.isPlaying = true;
            console.debug(`playing DVBT channel :  ${bw},${isc}`);

            let ChannelInfo = {
                TYPE: 2,
                ModulationType: modulationtype,
                SymbolRate: symbolrate,
                Frequency: frecuency / 1000000,
                PTC: ptc,
                ProgNum: 1,
                MINOR_NUMBER_ONE_PART: -2,
                ProgramNumber: isc,
                HotelProgramType: 1,
                Bandwidth: bw,
            };

            try {
                let pluginTVMW = document.getElementById("sefTVMW");
                pluginTVMW.Open("TVMW", "1.000", "TVMW");
                pluginTVMW.Execute("SetSource", "0");
                sefDVB.Open("Window", "1.000", "Window");
                console.debug(JSON.stringify(ChannelInfo));
                sefDVB.Execute("SetChannelHotel", JSON.stringify(ChannelInfo), 2);
                sefDVB.Close();
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },

        playATSC(ptc, minor, modulationtype, channeltype, tvmode, major) {
            this.isDVBT = true;
            this.isPlayer = false;
            this.isPlaying = true;
            console.debug(`playing ATSC channel :  ${ptc},${minor},${modulationtype},${channeltype},${tvmode}`);

            try {
                let pluginTVMW = document.getElementById("sefTVMW");
                pluginTVMW.Open("TVMW", "1.000", "TVMW");
                pluginTVMW.Execute("SetSource", "0");
                sefDVB.Open("Window", "1.000", "Window");
                sefDVB.Execute("SetChannelDirectEx", major ? major : ptc, minor, modulationtype, channeltype, tvmode);
                sefDVB.Close();
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },

        playDVBC(frecuency, bw, isc, ptc, symbolrate, modulationtype) {
            this.isDVBT = true;
            this.isPlayer = false;
            this.isPlaying = true;
            console.debug(`playing DVBC channel :  ${bw},${isc}`);

            let ChannelInfo = {
                TYPE: 4,
                ModulationType: modulationtype,
                SymbolRate: symbolrate,
                Frequency: frecuency / 1000000,
                PTC: ptc,
                ProgNum: 1,
                MINOR_NUMBER_ONE_PART: -2,
                ProgramNumber: isc,
                HotelProgramType: 1,
                Bandwidth: bw,
            };

            try {
                let pluginTVMW = document.getElementById("sefTVMW");
                pluginTVMW.Open("TVMW", "1.000", "TVMW");
                pluginTVMW.Execute("SetSource", "0");
                sefDVB.Open("Window", "1.000", "Window");
                console.debug(JSON.stringify(ChannelInfo));
                sefDVB.Execute("SetChannelHotel", JSON.stringify(ChannelInfo), 1);
                sefDVB.Close();
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },
        stop() {
            clearInterval(samsung.replayHLSChannelInterval);
            try {
                //If we setSource to 48, after use the player, sefIPTV doesn't load the channel properly
                if (!this.isPlayer) {
                    let pluginTVMW = document.getElementById("sefTVMW");
                    if (pluginTVMW) {
                        // pluginTVMW.Open('TVMW','1.000','TVMW');
                        pluginTVMW.Execute("SetSource", "48");
                    }
                }
                this.isPlaying = false;
                if (sefIPTV) {
                    sefIPTV.Execute("StopCurrentChannel", 0);
                }
                if (sefPlayer) {
                    sefPlayer.Execute("Stop", 0);
                }
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },

        pause() {
            try {
                if (this.isPaused == true) {
                    if (sefPlayer) {
                        sefPlayer.Execute("Resume", 0);
                    }
                    this.isPaused = false;
                    this.cspeed = 1;
                } else {
                    if (sefPlayer) {
                        sefPlayer.Execute("Pause", 0);
                    }
                    this.isPaused = true;
                    this.cspeed = 0;
                }
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },

        resume() {
            try {
                if (this.isPaused == true) {
                    if (sefPlayer) {
                        sefPlayer.Execute("Resume", 0);
                    }
                    this.isPaused = false;
                    this.cspeed = 1;
                } else {
                    if (sefPlayer) {
                        sefPlayer.Execute("Pause", 0);
                    }
                    this.isPaused = true;
                    this.cspeed = 0;
                }
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },

        /**
         * Does not work with RTSP
         * @param time
         */
        jump(time) {
            //Can't jump backward while ffw
            /*	if(this.cspeed != 1){
					this.setSpeed(1);
				}/*/
            try {
                let offset = time - this.ctime;
                if (offset > 0) {
                    if (sefPlayer) {
                        sefPlayer.Execute("JumpForward", offset / 1000);
                    }
                } else if (sefPlayer) {
                    sefPlayer.Execute("JumpBackward", -(offset / 1000));
                }
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },

        /**
         * Duration in milliseconds
         * (The samsung docs says it is seconds ¬¬)
         * RTSP returns 0
         * HLS returns the buffer size
         * @returns
         */
        getDuration() {
            try {
                if (sefPlayer) {
                    return sefPlayer.Execute("GetDuration");
                }
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },

        setSpeed(speed) {
            this.cspeed = speed;
            try {
                console.debug(`set playback speed :${speed}`);
                if (sefPlayer) {
                    return sefPlayer.Execute("SetPlaybackSpeed", speed);
                }
            } catch (e) {
                console.error(`error: ${e.message}`, e);
            }
        },

        getTime() {
            //return time in second
            return samsung.video.ctime / 1000;
        },

        getAudioTracks() {
            if (this.isDVBT) {
                if (sefDVB) {
                    return JSON.parse(sefDVB.Execute("GetMultiAudioInfo"));
                }
            } else if (sefWindow) {
                let tracks = sefWindow.Execute("GetMultiAudioInfo");
                console.debug(`AUDIOS : ${tracks}`);
                return tracks ? JSON.parse(tracks) : null;
            }
        },

        getSubtitleTracks() {
            //console.debug("Subtitle list:" + sefWindow.Execute("GetSubtitleInfo"));

            if (this.isDVBT) {
                if (sefDVB) {
                    return JSON.parse(sefDVB.Execute("GetSubtitleInfo"));
                }
            } else if (sefWindow) {
                let sub = sefWindow.Execute("GetSubtitleInfo");
                console.debug(`SUBTITLES : ${sub}`);
                return sub ? JSON.parse(sub) : null;
            }
        },

        changeAudio(id, lang) {
            if (lang) {
                let movieLanguage = lang.iso6392;
                if (movieLanguage == "las") {
                    movieLanguage = "zz{";
                }
                //iterate audio tracks to select track by index
                for (let audioTrack = 0; audioTrack < samsung.video.getAudioTracks().NumOfAudio; audioTrack++) {
                    console.debug(`lan ${samsung.int2text(samsung.video.getAudioTracks().items[audioTrack].lang)}`);
                    if (
                        movieLanguage.indexOf(samsung.int2text(samsung.video.getAudioTracks().items[audioTrack].lang)) >
                        -1
                    ) {
                        samsung.video.changeAudio(audioTrack);
                        console.debug(`Set audio to ${movieLanguage}`);
                        break;
                    } else if (
                        samsung.int2text(samsung.video.getAudioTracks().items[audioTrack].lang) == "zz{" &&
                        movieLanguage == "las"
                    ) {
                        samsung.video.changeAudio(audioTrack);
                        console.debug(`Set audio to ${movieLanguage}`);
                        break;
                    }
                }
                return;
            }

            if (this.isDVBT) {
                if (sefDVB) {
                    return sefDVB.Execute("ChangeAudioByIndex", id);
                }
            } else if (sefWindow) {
                return sefWindow.Execute("ChangeAudioByIndex", id);
            }
        },

        setSubs(sh) {
            if (sefTV) {
                return sefTV.Execute("ShowCaption", sh);
            }
        },
        changeSubtitle(index) {
            if (sefWindow) {
                return sefWindow.Execute("ChangeSubtitle", index);
            }
        },
        setSubtitleUrl(index) {},
        getSubtitleStatus() {
            if (sefTV) {
                console.debug(`status : ${sefTV.Execute("GetSubtitleStatus")}`);
                return sefTV.Execute("GetSubtitleStatus");
            }
        },

        hideSubs() {
            samsung.video.setSubs(0);
        },
        hideUrlSubs() {},
    },

    app: {
        appsByName: new Array(),
        widgets: {},
        rootPath: "",
        loadList() {
            samsung.app.widgets = curWidget.getWidgetList();
            samsung.app.rootPath = siNNavi.GetPath(1);
            samsung.app.appsByName = new Array();
            for (let x = 0; x < samsung.app.widgets.length; x++) {
                samsung.app.loadAppInfo(x);
            }
        },

        loadAppInfo(id) {
            let url = `file://localhost${samsung.app.rootPath}/${samsung.app.widgets[id]}/config.xml`;
            let xmlhttp = new XMLHttpRequest();
            xmlhttp.widgetIdx = id;
            xmlhttp.onreadystatechange = function () {
                if (this.readyState == 4 && this.status == 200) {
                    let parser = new DOMParser();
                    let xmlDoc = parser.parseFromString(this.responseText, "text/xml");
                    let wname = xmlDoc.getElementsByTagName("widgetname");
                    let val = wname[0].childNodes[0].nodeValue;
                    val = val.toLowerCase();
                    samsung.app.appsByName[val] = samsung.app.widgets[this.widgetIdx];
                    console.debug(`value: ${val}, id: ${samsung.app.widgets[this.widgetIdx]}`);
                } //TODO handle errors
            };
            xmlhttp.open("GET", url, true);
            xmlhttp.send();
        },

        openByName(name) {
            name = name.toLowerCase();
            if (name == "browser") {
                samsung.app.openBrowser("www.google.com");
                return;
            }
            if (typeof samsung.app.appsByName[name] == "undefined") {
                console.error(`App ${name} is not in the list...`);
                return;
            }
            console.debug(`launching app id: ${samsung.app.appsByName[name]}`);
            samsung.api.widget.runSearchWidget(samsung.app.appsByName[name], "");
        },

        openBrowser(url) {
            if (typeof url == "undefined") {
                url = "www.google.com";
            }
            console.debug(`launching full browser: ${url}`);
            samsung.api.widget.runSearchWidget("29_fullbrowser", url);
        },
        openMirroring() {
            sefMirroring.Execute("RunWIFIDisplay");
        },
    },

    downloadEnded: false,
    downloadList: {
        source: [],
        dest: [],
        success: [],
        error: [],
        it: 0,
    },
    downloadAll(sources, dests, success, error) {
        samsung.downloadList.source = sources;
        samsung.downloadList.dest = dests;
        samsung.downloadList.success = new Array();
        samsung.downloadList.error = new Array();
        samsung.downloadList.onDownloadError = error;
        samsung.downloadList.onDownloadSuccess = success;
        for (let i = 0; i < samsung.downloadList.source.length; i++) {
            samsung.downloadList.success[i] = samsung.downloadAllIteration;
            samsung.downloadList.error[i] = samsung.downloadAllError;
        }
        samsung.download(
            samsung.downloadList.source[0],
            samsung.downloadList.dest[0],
            samsung.downloadList.success[0],
            samsung.downloadList.error[0],
        );
    },
    downloadAllIteration() {
        let sdl = samsung.downloadList;
        console.debug(`Downloaded: ${sdl.source[sdl.it]}`);
        sdl.it++;
        if (sdl.it < sdl.source.length) {
            samsung.download(sdl.source[sdl.it], sdl.dest[sdl.it], sdl.success[sdl.it], sdl.error[sdl.it]);
        } else if (typeof sdl.onDownloadSuccess != "undefined") {
            sdl.onDownloadSuccess();
        }
    },
    downloadAllError(msg) {
        let sdl = samsung.downloadList;
        let nmsg = `Error downloading file '${sdl.source[sdl.it]}': ${msg}`;
        console.error(nmsg);
        sdl.onDownloadError(nmsg);
    },

    /**
     * Other utils...
     */
    download(src, dst, success, error) {
        let siDownload = document.getElementById("siDownload");
        samsung.onDownloadSuccess = success;
        samsung.onDownloadError = error;
        siDownload.OnComplete = function (param) {
            let p = param.split("?");
            if (p[0] == "1000") {
                if (p[1] == "1") {
                    //						console.info("File downloaded: "+dst);
                    if (typeof samsung.onDownloadSuccess != "undefined") {
                        samsung.onDownloadSuccess();
                        //console.debug("Should have called onSuccess...");
                    } else {
                        console.debug("No onSuccess callback to call.");
                    }
                    //TODO onDownloadError :P
                } else {
                    console.warn(`Download complete WITH ERRORS!. FILE: ${src}`);
                    //						if(typeof samsung.onDownloadError != "undefined"){
                    //							samsung.onDownloadError("Download complete but with errors.");
                    //						}
                }
            } else if (p[0] == "1001") {
                if (typeof samsung.onDownloadProgress != "undefined") {
                    samsung.onDownloadProgress(p[1]);
                }
                //					console.debug("Download progress: "+p[1]+"%");
            } else if (p[0] == "1002") {
                if (typeof samsung.onDownloadSpeed != "undefined") {
                    samsung.onDownloadSpeed(p[1]);
                }
                //console.debug("Download speed: "+p[1]+" bytes/second");
            } else if (p[0] == "1003") {
                if (p[1] != 200) {
                    if (typeof samsung.onDownloadError != "undefined") {
                        samsung.onDownloadError(`Server return code: ${p[1]}`);
                    }
                }
                //					console.debug("Server return code: "+p[1]);
            } else {
                console.warn(`onComplete${param}`);
            }
        };

        siDownload.StartDownFile(src, dst);
    },

    int2text(num) {
        let n1 = num & 0xff;
        let n2 = (num >> 8) & 0xff;
        let n3 = (num >> 16) & 0xff;
        return String.fromCharCode(n3, n2, n1);
    },
};
