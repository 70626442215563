import { useEffect, useState } from "preact/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Media } from "../../hooks/apis/media";
import { Texts } from "../../utils/texts";
import { eventHandler } from "../../utils/eventHandler";
import focus from "../../utils/focus";
import { figmapx2vh, figmapx2vw, getLanguageNameFromIso } from "../../utils/utils";
import { KEYS } from "../../utils/keys";
import { memo, useContext, useMemo, useRef } from "react";
import { filterMovieTracks } from "../../utils/movies";
import { PlayerContext } from "../movies/player";
import { SESSION } from "../../utils/session";
import { STB } from "../../hooks/apis/stb";
import { setVideoPos } from "../../actions/uiActions";

const ChannelLangSelector = ({ playingChannel, toggleMenuState }) => {
    const dispatch = useDispatch();
    const { showMenu, setShowMenu, setMenuBlocked, isPlayer, movie, restartMenuTimeout, videoSuccessRef } =
        useContext(PlayerContext);

    //States
    const [langs, setLangs] = useState(null);
    const [currentLang, setCurrentLang] = useState(0);
    const [selector, showSelector] = useState(false);

    const currentLangRef = useRef();
    currentLangRef.current = currentLang;

    const langsRef = useRef();
    langsRef.current = langs;

    const SELECTOR_ID = "langSelector";
    const filterLang = isPlayer;
    const isMovieForceAudio = Media.checkForceAudio(movie);

    //Store data
    const texts = useSelector((state) => state.ui.texts);
    useEffect(() => {
        if (SESSION.cAudioTrackId) {
            const onAudioList = (list) => {
                if (!list) {
                    return;
                }
                if (isMovieForceAudio) {
                    list = movie.languages;
                }
                list.forEach((audio, ind) => {
                    if (audio.id == SESSION.cAudioTrackId) {
                        setCurrentLang(ind);
                        currentLangRef.current = ind;
                    }
                });
            };
            if (isMovieForceAudio) {
                onAudioList(movie.languages);
            } else {
                Media.getAudioList(onAudioList);
            }
        }
    }, []);

    useEffect(() => {
        if (selector) {
            showLanguageList();
            setMenuBlocked && setMenuBlocked(true);
            toggleMenuState && toggleMenuState();
        } else {
            if (setMenuBlocked) {
                setMenuBlocked(false);
                restartMenuTimeout();
            }
        }
    }, [selector]);

    useEffect(() => {
        setLangs(null);
    }, [playingChannel]);

    const showLanguageList = () => {
        const onAudioList = (list) => {
            if (!list) {
                list = [];
            }
            if (filterLang) {
                list = filterMovieTracks(list, movie.languages, isMovieForceAudio);
            }
            list?.length > 0 && setLangs(list);
            setTimeout(function () {
                focus.value.replace(SELECTOR_ID);
            }, 50);
        };
        if (isMovieForceAudio) {
            setLangs(movie.languages);
        } else {
            Media.getAudioList(onAudioList);
        }
    };

    const isFocused = (id) => {
        return focus.value.current === id;
    };

    const hideSelector = () => {
        toggleMenuState && toggleMenuState();
        showSelector(false);
        setTimeout(function () {
            focus.value.replace("btn-audio");
        }, 20);
    };

    const onkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        if (keyData) {
            switch (keyData.value) {
                case KEYS.left:
                    !isPlayer && eventHandler.stopPropagation(e);
                    break;

                default:
                    break;
            }
        }
    };

    const onSelectorkeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        if (keyData) {
            switch (keyData.value) {
                case KEYS.up:
                    setCurrentLang(langs?.[currentLang + 1] ? currentLang + 1 : 0);
                    break;
                case KEYS.down:
                    setCurrentLang(langs?.[currentLang - 1] ? currentLang - 1 : langs?.length - 1);
                    break;
                case KEYS.enter:
                    hideSelector();
                    SESSION.cAudioTrackId = langsRef.current?.[currentLangRef.current].id;
                    if (isPlayer && isMovieForceAudio) {
                        Media.cVideoURL = movie.forceAudioUrl.replace(
                            "{{lang}}",
                            langsRef.current?.[currentLangRef.current].iso6391,
                        );
                        videoSuccessRef.current = false;
                        Media.replayCurrentVideo();
                        // phillips
                        dispatch(setVideoPos("fullscreen"));
                    } else {
                        Media.changeAudio(langsRef.current?.[currentLangRef.current].id);
                    }
                    break;
                case KEYS.back:
                    hideSelector();
                    break;
                default:
                    break;
            }
        }
        eventHandler.stopPropagation(e);
    };

    return useMemo(() => {
        return (
            <>
                <div
                    id="btn-audio"
                    onClick={() => showSelector(true)}
                    onKeyDown={(e) => {
                        onkeydown(e);
                    }}
                    tabIndex={0}
                    focusClass="channelList-focus"
                    className={`icon icon-audio-channels-movies relative navigable ${
                        selector ? "iconActive" : "borderBottomTransparent"
                    }`}
                    style={{ marginRight: `${figmapx2vw(32)}`, lineHeight: `${figmapx2vh(isPlayer ? 64 : 80)}` }}
                >
                    <div
                        className={`lato ${
                            isFocused("btn-audio") || selector || focus.value.current === SELECTOR_ID
                                ? "iconNameFocused"
                                : "hidden"
                        }`}
                    >
                        {Texts.capitalize(texts["audio"])}
                    </div>
                    {selector ? (
                        <>
                            <div
                                onKeyDown={(e) => {
                                    onSelectorkeydown(e);
                                }}
                                id={`${SELECTOR_ID}`}
                                focusClass="channelList-focus"
                                tabIndex={0}
                                className={"selector w-full table navigable absolute channelList-focus text-center"}
                                style={{
                                    fontSize: `${figmapx2vw(30)}`,
                                    fontWeight: 400,
                                    height: !langs || langs?.length === 1 ? `${figmapx2vh(104)}` : null,
                                }}
                            >
                                <div className={"table-cell lato vertical-middle"} style={{ lineHeight: 1 }}>
                                    {langs?.[currentLang]?.name || playingChannel?.languageCode || texts["unavailable"]}
                                    {langs?.length > 1 ? (
                                        <div>
                                            <div className="icon icon-chevron-up absolute" />
                                            <div className="icon icon-chevron absolute" />
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </>
        );
    });
};

export default memo(ChannelLangSelector);
