import Query from "../../query";
import { SESSION } from "../../../utils/session";
import { PERMISSION } from "../../../utils/constants";
import { movie, movieVisualizations } from "../../../utils/movies";

type CallOptions = {
    stayGuestRoomId: number;
    last24h: boolean;
};

interface CallRentedOptions extends CallOptions {
    movie: movie;
}

export const getRentedMovies = (options: CallOptions, onSuccess?: (moviesIds: number[] | null) => void) => {
    if (SESSION.hasPermission(PERMISSION.NEW_MOVIES)) {
    } else {
        Query({
            query: `{
            rentedMovies(stayGuestRoomId:${options.stayGuestRoomId}, last24h: ${options.last24h}){
                movies{
                    extId
                }
            }
        }`,
            onResponse(res) {
                if (res?.data?.rentedMovies?.movies) {
                    let parseResponse: number[] = parseRentedMovies(res?.data?.rentedMovies?.movies);
                    if (onSuccess) {
                        onSuccess(parseResponse);
                    }
                } else if (res && (!res.data || (res.data && !res.data.rentedMovies))) {
                    if (onSuccess) {
                        onSuccess(null);
                    }
                } else if (res?.error) {
                    console.log("HAY ERRORES");
                }
            },
            onError() {
                console.log("ERROORR  onError");
            },
        });
    }
};

const parseRentedMovies = (rentedMovies: { extId: string }[]): number[] => {
    let parseResponse: number[] = [];
    rentedMovies.forEach((element: { extId: string }) => {
        parseResponse.push(parseInt(element.extId));
    });
    return parseResponse;
};

export const updateMovieTimes = (options: CallRentedOptions, onSuccess?: (movie: movie | null) => void) => {
    if (SESSION.hasPermission(PERMISSION.NEW_MOVIES)) {
    } else {
        Query({
            query: `{
            rentedMovies(stayGuestRoomId:${options.stayGuestRoomId}, last24h: ${options.last24h}){
                movies{
                    extId
                    createdAt
                }
            }
            movieVisualizations(stayGuestRoomId:${options.stayGuestRoomId}, movieId: ${options.movie?.id}, orderBy: {field:"startTime", criteria: "DESC"} ){
                percentageReached
                startTime
                visualizationTime
            }
        }`,
            onResponse(res) {
                if (res?.data?.rentedMovies?.movies && res?.data?.movieVisualizations) {
                    let parseResponse: number[] = parseRentedMovies(res?.data?.rentedMovies?.movies);
                    if (parseResponse.includes(options.movie.id)) {
                        // remove Z at ends date if exists
                        options.movie.rentedAt = res?.data?.rentedMovies?.movies
                            .find(
                                (element: { extId: string; createdAt: string }) =>
                                    parseInt(element.extId) === options.movie.id,
                            )
                            .createdAt.endsWith("Z")
                            ? res?.data?.rentedMovies?.movies
                                  .find(
                                      (element: { extId: string; createdAt: string }) =>
                                          parseInt(element.extId) === options.movie.id,
                                  )
                                  .createdAt.slice(0, -1)
                            : res?.data?.rentedMovies?.movies.find(
                                  (element: { extId: string; createdAt: string }) =>
                                      parseInt(element.extId) === options.movie.id,
                              ).createdAt;
                    }
                    const movieUpdated = updateMovie(parseResponse, options.movie, res?.data?.movieVisualizations);
                    if (onSuccess) {
                        onSuccess(movieUpdated);
                    }
                } else if (res && (!res.data || (res.data && !res.data.rentedMovies))) {
                    if (onSuccess) {
                        onSuccess(null);
                    }
                } else if (res?.error) {
                    console.log("HAY ERRORES");
                }
            },
            onError() {
                console.log("ERROORR  onError");
            },
        });
    }
};

const updateMovie = (rentedMovies: number[], movie: movie, times: movieVisualizations[]): movie => {
    if (!rentedMovies.includes(movie.id)) {
        movie.rented = false;
    } else {
        movie.rented = true;
        movie.visualizations = times;
    }
    return movie;
};
