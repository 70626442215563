/**
 * This is a layer that is common for the interface and the development tests.
 */
import { STATE, STORAGE_KEY } from "../../../utils/constants";
import { dispatchNewMediaEvent, EVENTS } from "../../../utils/eventsConst";
import { Logger } from "../../../utils/logger";
import { hideInterface } from "../../../utils/utils";
import { Media } from "../media.js";
import { STB } from "../stb.js";
import { hcap } from "./hcap.js";
import { idcap } from "./idcap.js";
export const procentric = {
    //Media player variables
    media: null,
    mediaPosition: 0,
    mediaLength: 0,
    cspeed: 1,
    isPaused: false,

    //Channel variables
    currentChannelType: "",
    currentChannelIp: "239.255.65.56",
    currentChannelPort: 1234,
    currentChannelFreq: 0,
    currentChannelProgramNumber: 0,
    currentChannelAudioPID: 0,
    currentChannelVideoPID: 0,

    currentAudioInd: null,
    audioList: null,
    currentSubtitleInd: null,
    subtitleList: null,

    isFullScreen: false,
    isPlaying: false,
    idcapSupport: false,

    loadingInterval: null,
    pauseInterval: null,
    mediaPlayTimeout: null,
    proidiomSessionId: "ENS46313011810131030",
    destroyingMedia: null,
    destroyingTimeout: null,
    timesInPosition: 0,
    prevInput: 1,
    APP_BROWSER_ID: "144115188075855877",
    APP_PHOTOVIDEO_ID: "201604211753030001",
    APP_MUSIC_ID: "201604211753030002",
    APP_BLUETOOTH_ID: "244115188075859015",
    APP_NETFLIX_ID: "244115188075859013",
    APP_YOUTUBE_ID: "144115188075859002",
    NETFLIX_TOKEN:
        "T3x4PBcRXt3pbvTTMrj7mD3W5tyrcCTXnU64Fhpyenr/dB51Z8/9GGLpYZffr6JRJbwkN9Lny7+URQbSjkMzFXXH7Sv/38zbTbcXlpjW/TZXr6Bi3vIfeNK6XW8hFmctP1U+JUi/muBQalDgHZEddhDcppg1T842/73EkCcfQI0tIFbBh/XVqsIE1erWLzNki/PIQxjOWpYeQPIqh1p2ci9dW4TXPqzejBP3kqsVGeFgZ5TFJMnlvqBJj8rpigFTlZYTzZwZU2fkcgeYsbShccuwrZOyrwVfp1CPwiKQgwU+VeywQLrgSeFgWK8PQzssAOxsTQIjlsRL/lCWYHi5mw==",
    UDP_PORT_IN: 6666,
    UDP_PORT_OUT: 6665,

    init() {
        //TODO this is only needed on the first startup.
        procentric.setProperty("boot_sequence_option", "1");
        procentric.setProperty("instant_power", "1");
        procentric.setProperty("tv_volume_ui", "1");
        procentric.setProperty("tv_channel_ui", "0");
        procentric.setProperty("soft_ap_ui", "1");
        procentric.setProperty("clock_source", "ntp");
        procentric.setProperty("tv_channel_attribute_floating_ui", "0");
        procentric.setProperty("installation_menu_password", "7777");

        //GET TV property
        procentric.getProperty("model_name", (value) => {
            localStorage.setItem("tvModel", value);
            STB.checkCCBuiltIn(value);
        });
        procentric.getProperty("platform_version", (value) => {
            localStorage.setItem("firmwareVersion", value);
        });

        //CHECK IDCAP SUPPORT
        idcap.request("idcap://configuration/idcapmode/get", {
            parameters: {},
            onSuccess: function (s) {
                procentric.idcapSupport = true;
            },
            onFailure: function (err) {
                console.log("onFailure : errorMessage = " + err.errorMessage);
            },
        });

        this.setTVInput();
        this.registerEventListeners();
        this.registerApps();

        //DEVELOPMENT info
        //this.appListFetch();
    },

    registerEventListeners() {
        //Control de evento de encendido/apagado
        document.addEventListener(
            "power_mode_changed",
            () => {
                let onLoaded = function (state) {
                    console.debug(`STATE: ${state}`);
                    if (state == "online") {
                        Media.replayCurrentVideo();
                    } else if (state == "OFF") {
                        STB._resetCredentialsOnPowerOff();
                    }
                };
                procentric.getStatus(onLoaded);
            },
            false,
        );

        document.addEventListener(
            "network_event_received",
            (param) => {
                // {Number} param.event - network event. (hcap.network.NetworkEventType)
                if (
                    param.event === hcap.network.NetworkEventType.ETHERNET_UNPLUGGED ||
                    param.event === hcap.network.NetworkEventType.UNABLE_REACH_INTERNET
                ) {
                    //document.location.href = "ha.html";
                    // STB.gotoOfflineMode();
                    // let e = new CustomEvent(EVENTS.type.CONNECTION, {
                    //     detail: EVENTS.CONNECTION.ERROR,
                    // });
                    // document.dispatchEvent(e);
                }
            },
            false,
        );

        document.addEventListener(
            "external_input_changed",
            () => {
                procentric.onInputChange();
            },
            false,
        );

        document.addEventListener(
            "cec_data_received",
            (param) => {
                console.log("eventooooo: " + JSON.stringify(param.data));
                procentric.avoidErrorWithBgMusic();
            },
            false,
        );

        document.addEventListener("media_event_received", procentric.onMediaEvent, false);
    },

    avoidErrorWithBgMusic() {
        hcap.externalinput.getCurrentExternalInput({
            onSuccess(s) {
                if (s.type == 8) {
                    if (procentric.media) {
                        procentric.media.stop({
                            onSuccess() {
                                console.log("Media stopped");
                            },
                            onFailure(f) {
                                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                            },
                        });
                    }
                    Media.setVideoSize(0, 0, 100, 100);
                    hideInterface();
                    sessionStorage.setItem("outOfInterface", true);
                }
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    sleep(milliseconds) {
        let start = new Date().getTime();
        for (let i = 0; i < 1e7; i++) {
            if (new Date().getTime() - start > milliseconds) {
                break;
            }
        }
    },

    setPowerOnTime(hour, minutes) {
        hcap.time.setPowerOnTime({
            hour,
            minute: minutes,
            onSuccess() {
                console.log(`Power on time set to : ${hour}:${minutes}`);
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    setVolumeLevel(level) {
        hcap.volume.setVolumeLevel({
            level,
            onSuccess() {
                console.log(`Volume level set to : ${level}`);
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    playTest(ip, port) {
        let param = {
            channelType: hcap.channel.ChannelType.IP,
            ip,
            port: parseInt(port),
            ipBroadcastType: hcap.channel.IpBroadcastType.UDP,
            onSuccess() {
                procentric.currentChannelType = "igmp";
            },
            onFailure(e) {
                console.error(`Failed to change channel to: 'igmp:\\${ip}:${port}' (ErrorMessage: ${e.errorMessage})`);
            },
        };
        hcap.channel.requestChangeCurrentChannel(param);
    },

    getVolumeLevel(callback) {
        hcap.volume.getVolumeLevel({
            onSuccess(s) {
                console.log(`Current Volume level : ${s.level}`);
                if (callback) {
                    callback(s);
                }
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    sendKey(code) {
        hcap.key.sendKey({
            virtualKeycode: code,
            onSuccess() {
                console.log(`onSuccess: Send key: ${code}`);
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    ///////////////////////////////////////////////////////////////////////
    ///		PROPERTY UTILS												///
    ///////////////////////////////////////////////////////////////////////
    ///																	///
    /// Some interesting properties:									///
    ///		- model_name												///
    ///		- mac_address												///
    ///		- display_resolution										///
    ///		- boot_sequence_option: Make sure it's always set to 1.		///
    ///		- tv_volume_ui: Turn to 0 to control volume from our UI.	///
    ///		- tv_channel_attribute_floating_ui: Make sure it's always 0.///
    ///////////////////////////////////////////////////////////////////////

    /**
     * Sets the property to the specified value.
     */
    setProperty(key, value) {
        let param = {
            key,
            value,
            onSuccess() {
                console.debug(`Property set >>> ${key}: ${value}`);
            },
            onFailure(e) {
                console.error(`Error setting property: '${key}' to value '${value}': ${e.errorMessage}`, e);
            },
        };
        hcap.property.setProperty(param);
    },

    /**
     * Gets the value of a key. Calls the callback method with the value as a
     * parameter when successful.
     * Example:
     * <code>procentric.getProperty("mac_address",function(value){procentric.mac = value;});</code>
     * @param key
     * @param callback
     */
    getProperty(key, callback) {
        let param = {
            key,
            onSuccess(r) {
                console.debug(`Property get <<< ${key}: ${r.value}`);
                callback(r.value);
            },
            onFailure(e) {
                console.error(`Error getting property: '${key}': ${e.errorMessage}`, e);
            },
        };
        hcap.property.getProperty(param);
    },

    onInputChange() {
        hcap.externalinput.getCurrentExternalInput({
            onSuccess(s) {
                if (sessionStorage.getItem("outOfInterface") === "true" && s.type == 1) {
                    STB.ensureZafiroInterface();
                    return;
                } else if (sessionStorage.getItem("outOfInterface") === "true") {
                    return;
                }
                if (s.type == 8) {
                    procentric.prevInput = s.type;
                    return;
                }
                if (s.type == 1) {
                    // en los modelos LY , parece que se lanza el evento "external_input_changed"
                    //en algun momento cuando reproducimos un trailer
                    /*  if (procentric.prevInput == 8 || procentric.prevInput == 1) return; //este control para las LY, al r
                    // STB.fullReloadInterface();
                    Focus.onLimbo = false;
                    STB.inExternalInput = false;
                    body().style.display = "block";
                    ONINACTIVE.useLongTimeout(false);
                    WIDGET.goBack = true;
                    Media.Video._showPIG(1, -1, -1, null, 1, 1);
                    var cLayout = Ingesuite.getCurrentLayout();
                    cLayout.handleEvent._preHandle = Ingesuite.back_preHandle;
                    if (cLayout.__type == "widgets" && WIDGET.hasVideoEmbeded) {
                        cLayout.restoreState();
                    }*/
                } else {
                    //Media.stop();
                    /*procentric.setHCAPMode(hcap.mode.HCAP_MODE_1);
                    hcap.Media.shutDown({
                        onSuccess: function () {
                            console.log("shutDownonSuccess");
                        },
                        onFailure: function (f) {
                            console.log("shutDownonFailure : errorMessage = " + f.errorMessage);
                        },
                    });
*/

                    if (procentric.media) {
                        procentric.media.stop({
                            onSuccess() {
                                console.log("Media stopped");
                            },
                            onFailure(f) {
                                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                            },
                        });
                    }
                    Media.setVideoSize(0, 0, 100, 100);
                    hideInterface();
                    sessionStorage.setItem("outOfInterface", true);
                }
                procentric.prevInput = s.type;
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    setHDMI(hdmi, notHide) {
        if (!notHide) {
            hideInterface();
        }
        let type = hcap.externalinput.ExternalInputType.HDMI;
        let index = hdmi - 1;

        if (hdmi == "AV1") {
            type = hcap.externalinput.ExternalInputType.COMPOSITE;
            index = 0;
        }
        hcap.externalinput.setCurrentExternalInput({
            type,
            index,
            onSuccess() {
                sessionStorage.setItem("outOfInterface", true);
            },
            onFailure(f) {
                document.documentElement.style.display = "";
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    setTVInput() {
        hcap.externalinput.setCurrentExternalInput({
            type: hcap.externalinput.ExternalInputType.TV,
            index: 0,
            onSuccess() {
                //STB.reload();
                sessionStorage.setItem("outOfInterface", false);
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    setHCAPMode(mode) {
        hcap.mode.setHcapMode({
            mode,
            onSuccess() {
                console.log("onSuccess");
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    changeToUSB() {
        let content = [];
        for (let i = 0; i < procentric.appList.length; i++) {
            if (procentric.appList[i].id == procentric.APP_PHOTOVIDEO_ID) {
                //boton Photo & Video
                // content.push(
                //     Button({
                //         name: T("PHOTO_VIDEO"),
                //         action(container) {
                //             container.close();
                //             procentric.appOpenById(procentric.APP_PHOTOVIDEO_ID);
                //         },
                //     })
                // );
            } else if (procentric.appList[i].id == procentric.APP_MUSIC_ID) {
                //boton Music
                // content.push(
                //     Button({
                //         name: T("MUSIC"),
                //         action: function (container) {
                //             container.close();
                //             procentric.appOpenById(procentric.APP_MUSIC_ID);
                //         },
                //     })
                // );
            }
        }
        if (content.length > 0) {
            this.chooseModeUsb(content);
        } else {
            //no usb apps switch to externalInput
            hcap.externalinput.setCurrentExternalInput({
                type: hcap.externalinput.ExternalInputType.USB,
                index: 0,
                onSuccess() {
                    // Focus.onLimbo = true;
                    // Media.Video._showPIG(false);
                    // body().style.display = "none";
                },
                onFailure(f) {
                    console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                },
            });
        }
    },

    chooseModeUsb() {
        // var confirmPopUp = Windows.makeWindowContext({
        //     create: function () {
        //         var itemToFocus = Focus.elementOnFocus;
        //         this.infobox = InfoBox({
        //             title: T("USB_MODE").toUpperCase(),
        //             style: "parentalPopUp",
        //             zIndex: 100,
        //             content: buttons,
        //             firstFocus: 0,
        //             onclose: function () {
        //                 if (itemToFocus && itemToFocus != "noFocus") Focus.setFocus(itemToFocus);
        //                 confirmPopUp.destruct();
        //             }.bind(this, itemToFocus),
        //         }).setup();
        //     },
        //     setup: function (layout) {
        //         layout.addWindow(this);
        //     },
        //     handleEvent: {
        //         _priorityHandle: function (ev) {
        //             var code = ev.key_code;
        //             var btn = Events.eventHandleTable[ev.key_code];
        //             switch (code) {
        //                 case 32: //space
        //                 case Events.IR_KEYCODE_BACK:
        //                     this.infobox.close();
        //                     return true;
        //                 case Events.IR_KEYCODE_MENU:
        //                 case 78: // 'N' //'
        //                 case 110: // 'n' //
        //                     closeAllPopUp();
        //                     Navigate.goHome();
        //                     return true;
        //             }
        //         },
        //     },
        // });
        // confirmPopUp.construct();
        // confirmPopUp.setup(Ingesuite.getCurrentLayout());
    },
    getNetworkInformation(onSuccess) {
        hcap.network.getNetworkInformation({
            onSuccess(s) {
                if (onSuccess) {
                    onSuccess(s);
                }
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },
    getDeviceMac(onSuccess) {
        hcap.network.getNetworkDevice({
            index: 1,
            onSuccess(s) {
                if (onSuccess) {
                    onSuccess(s.mac);
                }
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    ///////////////////////////////////////////////////////////////////////
    ///		 PRELOADED APP UTILS										///
    ///////////////////////////////////////////////////////////////////////

    appList: new Object(),

    appListFetch() {
        hcap.preloadedApplication.getPreloadedApplicationList({
            onSuccess(s) {
                procentric.appList = s.list;
                console.debug("App list: ", s.list);
                for (let i = 0; i < procentric.appList.length; i++) {
                    console.log(procentric.appList[i].title + "-" + procentric.appList[i].id);
                }
            },
            onFailure(f) {
                console.error(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    appOpenByName(name, reset) {
        reset = reset || false;
        for (let i = 0; i < procentric.appList.length; i++) {
            if (procentric.appList[i].title == name) {
                procentric.appOpenById(procentric.appList[i].id, reset);
            }
        }
    },

    appOpenById(id, reset) {
        if (reset) {
            hcap.preloadedApplication.destroyPreloadedApplication({
                id,
                onSuccess() {
                    sessionStorage.setItem("outOfInterface", true);
                    console.debug("onSuccess");
                },
                onFailure(f) {
                    console.error(`onFailure : errorMessage = ${f.errorMessage}`);
                },
            });
        }
        console.debug(`Opening app idx: ${id}`);
        hcap.preloadedApplication.launchPreloadedApplication({
            id,
            onSuccess() {
                console.debug("onSuccess");
            },
            onFailure(f) {
                console.error(`onFailure : errorMessage = ${f.errorMessage}`);
                let e = new CustomEvent(EVENTS.type.POPUP, {
                    detail: { type: EVENTS.POPUP.NOT_AVAILABLE },
                });
                document.dispatchEvent(e);
                procentric.registerApps();
            },
        });
    },

    ///////////////////////////////////////////////////////////////////////
    ///		TIME UTILS													///
    ///////////////////////////////////////////////////////////////////////

    /**
     * Sets the TV's system time. Sometimes the effects won't apply until
     * the TV restarts.
     */
    setTime(year, month, day, hour, minute, seconds, timeOffset) {
        let param = {
            year,
            month,
            day,
            hour,
            minute,
            second: seconds,
            gmtOffsetInMinute: timeOffset * 60,
            isDaylightSaving: false,
            onSuccess() {
                console.debug(`Time synchronized: ${year}-${month}-${day} ${hour}:${minute}`);
            },
            onFailure(e) {
                console.error(`Error synchronizing time: ${e.errorMessage}`, e);
            },
        };

        hcap.time.setLocalTime(param);
    },

    ///////////////////////////////////////////////////////////////////////////
    ///		INTEGRATION UTILS												///
    ///////////////////////////////////////////////////////////////////////////

    /**
     * If procentric.media is null, stops the TV channel, otherwise stops the media
     * player.
     */
    stopMediaOrChannel() {
        if (typeof procentric == "undefined") {
            return;
        }
        if (procentric.media) {
            procentric.stopAndPlayMedia();
        } else {
            procentric.stopChannel();
        }
    },

    /**
     * If procentric.media is null, stops the TV channel, otherwise destroys the
     * media player.
     */
    destroyMediaOrChannel() {
        console.debug(`Destroy media.${procentric.media}`);
        clearInterval(this.setLanguageInterval);
        clearInterval(this.pauseInterval);
        if (procentric.currentChannelType == "") {
            procentric.currentAudioInd = 0;
            procentric.destroyMedia();
            /*setTimeout(function(){
					procentric.stopChannel();
				}, 1000);*/
        } else {
            //After stopping the media, if an Rf cable is connected, a channel
            //will start playing :s
            procentric.stopChannel();
        }
    },

    ensureLangAfterPlaying() {
        setTimeout(() => {
            clearInterval(this.setLanguageInterval);
        }, 20000);
        ///////////////////////////////////////////////////////
        // Al comenzar la reproducccion de una peli
        // poner la pista de audio correcta para las pelis multi
        // if (Ingesuite.getCurrentLayout().__type === "whileplaying") {
        //     this.setLanguageInterval = setInterval(
        //         function () {
        //             //si ya ha empezado la reproduccion de la pelicula
        //             if (Media.Video.getStreamPos() > 0) {
        //                 //y la pelicula es de tipo multi
        //                 if (
        //                     Media._lastmovie &&
        //                     Media._lastmovie.languages &&
        //                     Media._lastmovie.languages[0].isMultiLanguage
        //                 ) {
        //                     var audioList = Media._lastmovie.languages;
        //                     tempList = JSON.stringify(audioList);
        //                     var movieLangs = JSON.parse(tempList);

        //                     // se busca el lenguaje a fijar //ej es
        //                     var langToSet = null;
        //                     for (var i = 0; i < movieLangs.length; i++) {
        //                         if (movieLangs[i].id === Actions.cMovieLanguage) {
        //                             langToSet = movieLangs[i].iso6391;
        //                         }
        //                     }
        //                     var tempList = Media.Video.getAudioList();

        //                     //si aun no se tienen las pistas de audio de la pelicula, return
        //                     if (!tempList) {
        //                         return;
        //                     }

        //                     if (tempList && tempList.length === 1 && tempList[0] === "xx") {
        //                         clearInterval(this.setLanguageInterval);
        //                     } else {
        //                         clearInterval(this.setLanguageInterval);
        //                         var procAudioList = JSON.stringify(tempList);
        //                         procAudioList = JSON.parse(procAudioList);

        //                         //iterar sobre las pistas de audio devueltas por la tele y
        //                         // fijar la que corresponda
        //                         for (var i = 0; i < procAudioList.length; i++) {
        //                             if (langToSet === procAudioList[i]) {
        //                                 Media.Video.changeAudio(i, null);
        //                                 return;
        //                             } else if (procAudioList[i] === "xx" && langToSet === "de" && i <= 3) {
        //                                 Media.Video.changeAudio(i, null);
        //                                 break;
        //                             } else if (procAudioList[i] === "xx" && langToSet === "ls" && i > 3) {
        //                                 Media.Video.changeAudio(i, null);
        //                                 break;
        //                             }
        //                         }
        //                     }
        //                 } else {
        //                     clearInterval(this.setLanguageInterval);
        //                 }
        //             }
        //         }.bind(this),
        //         500
        //     );
        // }
        ///////////////////////////////////////////////////////
    },

    ///////////////////////////////////////////////////////////////////////////
    ///		TV CHANNEL UTILS												///
    ///////////////////////////////////////////////////////////////////////////

    changeChannelIPTV(ip, port, videoPid, audioPid, sessionId) {
        ///////////////////////////////////////////////////////////////
        ///////// Esperamos a que se destruya el objeto media /////////
        ///////// antes de reproducir un canal             ////////////
        ///////////////////////////////////////////////////////////////
        if (this.destroyingMedia) {
            clearTimeout(this.changeChannelTimeout);
            this.changeChannelTimeout = setTimeout(
                (() => {
                    procentric.changeChannelIPTV(ip, port, videoPid, audioPid, sessionId);
                }).bind(ip, port, videoPid, audioPid, sessionId),
                500,
            );
            return;
        }
        clearTimeout(this.changeChannelTimeout);

        /////////////////////////////////////////////////

        if (port == undefined) {
            port = 1234;
        }

        if (typeof port == "string") {
            port = Number(port, 10);
        }
        if (typeof hcap === "undefined") {
            return;
        }
        let param = {
            channelType: hcap.channel.ChannelType.IP,
            ip,
            port,
            ipBroadcastType: hcap.channel.IpBroadcastType.UDP,
            onSuccess() {
                procentric.currentChannelType = "igmp";
                procentric.currentChannelIp = ip;
                procentric.currentChannelPort = port;
                if (videoPid != undefined) {
                    procentric.currentChannelVideoPID = videoPid;
                }
                if (audioPid != undefined) {
                    procentric.currentChannelAudioPID = audioPid;
                }
                console.debug(`Channel successfully changed to: 'igmp:\\${ip}:${port}'.`);

                //IP 239.255.255.248 is used to avoid any channel to play
                if (ip != "239.255.255.248" && Media.playingBgMusic != null && !Media.playingBgMusic) {
                    setTimeout(() => {
                        console.debug("ACTIONS TIMEOUT");
                        procentric.getAudioList();
                        procentric.getSubtitleList();
                        procentric.getCurrentAudio();
                        procentric.getCurrentSubtitle();
                    }, 3000);
                }
            },
            onFailure(e) {
                console.error(`Failed to change channel to: 'igmp:\\${ip}:${port}' (ErrorMessage: ${e.errorMessage})`);
            },
        };

        if (videoPid != undefined) {
            param.programNumber = videoPid;
        }

        if (audioPid != undefined) {
            param.audioPid = audioPid;
        }

        //ProIdiom
        if (typeof sessionId == "string") {
            param.sessionId = procentric.strToHex(sessionId);
            console.debug(`${sessionId} -> ${param.sessionId}`);
        }

        hcap.channel.requestChangeCurrentChannel(param);

        if (!procentric.isFullScreen && ip != "239.255.255.248" && Media.playingVideoInfolayout) {
            procentric.resetVideoSize(
                procentric.lastVideoSize.x,
                procentric.lastVideoSize.y,
                procentric.lastVideoSize.width,
                procentric.lastVideoSize.height,
            );
        }
    },

    changeChannelDVBT(frequency, programNumber) {
        console.debug(`Playing DVBT channel , ${frequency}, ${programNumber}`);
        let param = {
            channelType: hcap.channel.ChannelType.RF,
            frequency: frequency * 1000, // in Hz
            programNumber,
            rfBroadcastType: hcap.channel.RfBroadcastType.TERRESTRIAL,
            onSuccess() {
                procentric.currentChannelType = "dvbt";
                procentric.currentChannelFreq = frequency;
                procentric.currentChannelProgramNumber = programNumber;

                console.debug(`Channel successfully changed to: 'dvbt:\\${frequency}:${programNumber}'.`);
                setTimeout(() => {
                    procentric.getAudioList();
                    procentric.getSubtitleList();
                    procentric.getCurrentAudio();
                    procentric.getCurrentSubtitle();
                }, 3000);
            },
            onFailure(e) {
                console.error(
                    `Failed to change channel to: 'dvbt:\\${frequency}:${programNumber}' (ErrorMessage: ${e.errorMessage})`,
                );
            },
        };

        hcap.channel.requestChangeCurrentChannel(param);
    },

    changeChannelDVBC(frequency, programNumber) {
        let param = {
            channelType: hcap.channel.ChannelType.RF,
            frequency: frequency * 1000, // in Hz
            programNumber,
            rfBroadcastType: hcap.channel.RfBroadcastType.CABLE,
            onSuccess() {
                procentric.currentChannelType = "dvbc";
                procentric.currentChannelFreq = frequency;
                procentric.currentChannelProgramNumber = programNumber;
                console.debug(`Channel successfully changed to: 'dvbc:\\${frequency}:${programNumber}'.`);

                setTimeout(() => {
                    procentric.getAudioList();
                    procentric.getSubtitleList();
                    procentric.getCurrentAudio();
                    procentric.getCurrentSubtitle();
                }, 3000);
            },
            onFailure(e) {
                console.error(
                    `Failed to change channel to: 'dvbc:\\${frequency}:${programNumber}' (ErrorMessage: ${e.errorMessage})`,
                );
            },
        };

        hcap.channel.requestChangeCurrentChannel(param);
    },

    changeChannelDVBS: function (frequency, programNumber, satelliteId) {
        var param = {
            channelType: hcap.channel.ChannelType.RF,
            programNumber: programNumber,
            frequency: frequency,
            satelliteId: satelliteId,
            polarization: hcap.channel.Polarization.UNKNOWN,
            rfBroadcastType: hcap.channel.RfBroadcastType.SATELLITE_2,
            onSuccess: function () {
                console.debug("requestChangeCurrentChannel - " + JSON.stringify(param));
                var param2 = {
                    channelType: hcap.channel.ChannelType.RF,
                    frequency: frequency * 1000,
                    programNumber: programNumber,
                    satelliteId: satelliteId,
                    polarization: hcap.channel.Polarization.UNKNOWN,
                    rfBroadcastType: hcap.channel.RfBroadcastType.UNKNOWN,
                    onSuccess: function () {
                        console.debug("SECOND TUNE -- ##### - requestChangeCurrentChannel - " + JSON.stringify(param));
                        console.log("onSuccess");
                    },
                    onFailure: function (f) {
                        console.log("onFailure : errorMessage = " + f.errorMessage);
                        console.debug("SECOND TUNE -- #####- requestChangeCurrentChannel - onFailure" + f.errorMessage);
                    },
                };
                hcap.channel.requestChangeCurrentChannel(param2);
                console.log("onSuccess");
            },
            onFailure: function (f) {
                console.log("onFailure : errorMessage = " + f.errorMessage);
                console.debug("requestChangeCurrentChannel - onFailure" + f.errorMessage);
            },
        };

        hcap.channel.requestChangeCurrentChannel(param);
    },

    changeChannelATSC(major, minor) {
        // Minor is set to 0 if the channel's source is analog. 1-999 if the channel's source is digital
        // minor number is assigned by the content provider
        //if(minor==0){
        let broadcastType = hcap.channel.RfBroadcastType.ANALOG_NTSC;
        /*}else{
				broadcastType = hcap.channel.RfBroadcastType.TERRESTRIAL;
			}*/

        let param = {
            channelType: hcap.channel.ChannelType.RF,
            majorNumber: major,
            minorNumber: minor,
            rfBroadcastType: broadcastType,
            onSuccess() {
                procentric.currentChannelType = "atsc";

                console.debug(`Channel successfully changed to: 'atsc  major:\\${major},  minor:${minor}'.`);

                setTimeout(() => {
                    procentric.getAudioList();
                    procentric.getSubtitleList();
                    procentric.getCurrentAudio();
                    procentric.getCurrentSubtitle();
                }, 3000);
            },
            onFailure() {
                console.debug(`Failed to changed to: 'atsc  major:\\${major},  minor:${minor}'.`);
            },
        };

        hcap.channel.requestChangeCurrentChannel(param);
    },

    stopChannel() {
        hcap.channel.stopCurrentChannel({
            onSuccess() {
                console.debug("onSuccess");
            },
            onFailure(f) {
                console.debug("fail");
                console.debug(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
        /*  var param = {
            onSuccess: function () {
                console.debug("Channel successfully stopped.");
            },
            onFailure: function (e) {
                console.debug(
                    "Failed to stop the current channel: " + e.errorMessage
                );
                //It returns error when the channel is stopped
                //We don't have any means to determine when the channel
                //is playing or is stopped :S
                //So, we don't want these errors to be reported,
                //not even for debugging.
                //STB.error("Failed to stop the current channel: " + e.errorMessage);
            },
        };
        hcap.channel.stopCurrentChannel(param);*/
    },

    setStartChannel(ip, port) {
        let param = {
            channelType: hcap.channel.ChannelType.IP,
            ip,
            port,
            ipBroadcastType: hcap.channel.IpBroadcastType.UDP,
            onSuccess() {
                console.debug(`Start channel successfully set to: 'udp:\\${ip}:${port}'.`);
            },
            onFailure(e) {
                console.error(
                    `Failed to set start channel to: 'udp:\\${ip}:${port}' (ErrorMessage: ${e.errorMessage})`,
                );
            },
        };

        hcap.channel.setStartChannel(param);
    },

    ///////////////////////////////////////////////////////////////////////////
    ///		MEDIA PLAYER UTILS												///
    ///////////////////////////////////////////////////////////////////////////

    /**
     * Please, refer to the LG HCAP API documentation for a list of supported
     * protocol URLs and mime types.
     * Basically:
     * - http://...
     * - rtsp://...
     * And for mimeTypes:
     * - audio/mpeg
     * - audio/mp3
     * - video/mp4
     * - video/mpeg4
     * - video/mpeg
     */
    createMedia(url, mime) {
        let mediaParam = {
            url,
            mimeType: mime,
            //"drmType" : "NONE"
        };

        /*	if(typeof procentric.proidiomSessionId == "string" && url.indexOf('webmusic')==-1 ){   //esto es feo pero sino tengo que cambiar muchas llamadas.
				mediaParam.drmType="PROIDIOM";
				mediaParam.sessionId = procentric.strToHex(procentric.proidiomSessionId);
			}*/

        if (procentric.media) {
            console.error("Media is already created. Need to destroy previously created media first.");
            this.destroyMedia();
        } else {
            procentric.media = hcap.Media.createMedia(mediaParam);
            procentric.currentChannelType = "";
            if (procentric.media) {
                console.debug(`Media created: '${mediaParam.url}'`);
            } else {
                hcap.Media.startUp({
                    onSuccess() {
                        procentric.media = hcap.Media.createMedia(mediaParam);
                        if (procentric.media) {
                            console.debug(`Media created with startUP: '${mediaParam.url}'`);
                        } else {
                            console.error("Failed to create media");
                            if (Media.Video.positionInterval) {
                                clearInterval(Media.Video.positionInterval);
                            }
                        }
                    },
                    onFailure(f) {
                        console.debug(`Failed to start up = ${f.errorMessage}`);
                        if (Media.Video.positionInterval) {
                            clearInterval(Media.Video.positionInterval);
                        }
                    },
                });
            }

            //play media
            if (procentric.media) {
                procentric.mediaLength = 0;
                procentric.mediaPosition = 0;
                procentric.mec = 0;
                let param = {
                    onSuccess() {
                        procentric.isPlaying = true;
                        setTimeout(() => {
                            procentric.getMediaInfo();
                        }, 500);
                    },
                    onFailure(e) {
                        console.error(`Media play error: ${e.errorMessage}`, e);
                        if (Media.Video.positionInterval) {
                            clearInterval(Media.Video.positionInterval);
                        }
                    },
                };
                procentric.media.play(param);
                procentric.cspeed = 1;
                procentric.isPaused = false;
            } else {
                console.debug("Media variable is null. Not playing.");
            }
        }
    },

    playHLSVideo(urlToPlay, loop) {
        if (this.startMediaTimeout) {
            clearTimeout(this.startMediaTimeout);
        }
        if (this.stoppingMedia || this.startingMedia) {
            this.startMediaTimeout = setTimeout(
                (() => {
                    procentric.playHLSVideo(urlToPlay, loop);
                }).bind(urlToPlay, loop),
                100,
            );
            return;
        }
        this.startingMedia = true;
        hcap.Media.startUp({
            onSuccess() {
                this.media = hcap.Media.createMedia({
                    url: urlToPlay,
                    mimeType: "application/x-mpegURL",
                });
                this.media.play({
                    repeatCount: loop ? 0 : 1,
                    onSuccess() {
                        procentric.startingMedia = false;
                        Media.playingMedia = true;
                        console.log(" Play onSuccess");
                    },
                    onFailure(f) {
                        this.startingMedia = false;
                        console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                    },
                });
            },
            onFailure(f) {
                this.startingMedia = false;
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    stopAndPlayMedia(url, loop) {
        console.log(`PLAY URL + ${url}`);
        if (this.stoppingMedia || this.startingMedia) {
            if (this.mediaTimeout) {
                clearTimeout(this.mediaTimeout);
            }
            this.mediaTimeout = setTimeout(
                (() => {
                    procentric.stopAndPlayMedia(url, loop);
                }).bind(url, loop),
                400,
            );
            return;
        }
        this.stoppingMedia = true;

        //console.log("ENTRO EN STOP MEDIA");
        if (!procentric.media) {
            procentric.media = hcap.Media.createMedia({});
        }

        if (procentric.media) {
            //console.log("ENTRO EN STOP MEDIA2");

            let statusParam = {
                onSuccess(s) {
                    console.log(`ENTRO state ${s.state}`);

                    if (s.state == "play") {
                        let param = {
                            onSuccess() {
                                console.log("Media stopped");
                                if (!url) {
                                    Media.playingMedia = false;
                                }

                                procentric.media.destroy({
                                    onSuccess() {
                                        console.log("DESTROY onSuccess");
                                        hcap.Media.shutDown({
                                            onSuccess() {
                                                procentric.stoppingMedia = false;
                                                if (!url) {
                                                    Media.playingMedia = false;
                                                }
                                                if (url) {
                                                    console.log(url);
                                                    procentric.playHLSVideo(url, loop);
                                                }
                                                console.log(" SHUT DOWN onSuccess");
                                            },
                                            onFailure(f) {
                                                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                                            },
                                        });
                                    },
                                    onFailure(f) {
                                        procentric.stoppingMedia = false;
                                        if (!url) {
                                            Media.playingMedia = false;
                                        }
                                        console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                                    },
                                });
                            },
                            onFailure(e) {
                                if (!url) {
                                    Media.playingMedia = false;
                                }
                                console.log(`Media stop error: ${e.errorMessage}`);
                            },
                        };
                        procentric.media.stop(param);
                    } else {
                        procentric.media.destroy({
                            onSuccess() {
                                console.log("DESTROY desde STOP onSuccess");
                                hcap.Media.shutDown({
                                    onSuccess() {
                                        procentric.stoppingMedia = false;
                                        if (!url) {
                                            Media.playingMedia = false;
                                        }
                                        if (url) {
                                            console.log(url);
                                            procentric.playHLSVideo(url, loop);
                                        }
                                        console.log("SHUTDOWN onSuccess");
                                    },
                                    onFailure(f) {
                                        console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                                    },
                                });
                            },
                            onFailure(f) {
                                procentric.stoppingMedia = false;
                                if (!url) {
                                    Media.playingMedia = false;
                                }

                                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                            },
                        });
                    }
                },
                onFailure(e) {
                    procentric.stoppingMedia = false;
                    if (!url) {
                        Media.playingMedia = false;
                    }
                    if (url) {
                        console.log(url);
                        procentric.playHLSVideo(url, loop);
                    }
                    console.log(`Error getting media status: ${e.errorMessage}`, e);
                },
            };
            procentric.media.getState(statusParam);
        } else {
            console.log("Media variable is null. Not stopping.");

            hcap.Media.shutDown({
                onSuccess() {
                    console.log("shutdown onSuccess");
                    procentric.stoppingMedia = false;
                    if (!url) {
                        Media.playingMedia = false;
                    }
                    if (url) {
                        console.log(url);
                        procentric.playHLSVideo(url, loop);
                    }
                },
                onFailure(f) {
                    console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                },
            });
        }
    },

    continueMedia() {
        return setTimeout(() => {
            let pauseParam = {
                onSuccess() {
                    console.debug("PAUSO video");
                    let resumeParam = {
                        onSuccess() {
                            console.debug("Resume video");
                        },
                        onFailure(e) {
                            console.error(`Resume video error: ${e.errorMessage}`, e);
                        },
                    };
                    procentric.media.resume(resumeParam);
                },
                onFailure(e) {
                    console.error(`PAUSO video error: ${e.errorMessage}`, e);
                },
            };
            procentric.media.pause(pauseParam);
        }, 500);
    },

    resumeMedia() {
        if (procentric.media) {
            // let param = {
            //     onSuccess () {
            //         console.debug("Continue playing");
            //     },
            //     onFailure (e) {
            //         console.error(`Media play error: ${  e.errorMessage}`, e);
            //     },
            // };

            if (procentric.isPaused == true) {
                /*	console.debug("IS PAUSED SO RESUME VIDEO" );
					procentric.media.resume(param);
					procentric.cspeed=1;
					procentric.isPaused= false;
					clearInterval(procentric.pauseInterval);*/

                console.debug("IS PAUSED SO RESUME VIDEO");

                setTimeout(() => {
                    let resumeParam = {
                        onSuccess() {
                            console.debug("Resume video");
                            procentric.continueMedia();
                        },
                        onFailure(e) {
                            console.error(`Resume video error: ${e.errorMessage}`, e);
                        },
                    };
                    procentric.media.resume(resumeParam);
                    procentric.cspeed = 1;
                    procentric.isPaused = false;
                    clearInterval(procentric.pauseInterval);
                }, 500);
            } else {
                console.debug("IS PLAYING SO PAUSE");
                procentric.pauseMedia();
            }
        } else {
            console.debug("Media variable is null. Not playing.");
        }
    },

    pauseMedia() {
        if (procentric.media) {
            let param = {
                onSuccess() {
                    console.debug("Pause playing");

                    procentric.getMediaPlayPosition();
                    //While pause, each 40 seconds resume and pause is
                    //executed to maintain the rtsp connection
                    if (procentric.pauseInterval) {
                        clearInterval(procentric.pauseInterval);
                    }
                    procentric.pauseInterval = setInterval(() => {
                        let param = {
                            onSuccess() {
                                //procentric.setMediaPlayPosition(procentric.mediaPosition);
                                setTimeout(() => {
                                    let param = {
                                        onSuccess() {
                                            console.debug("Pause playing");
                                        },
                                        onFailure(e) {
                                            console.error(`Media pause error: ${e.errorMessage}`, e);
                                        },
                                    };
                                    procentric.media.pause(param);
                                }, 100);
                            },
                            onFailure(e) {
                                console.error(`Media play error: ${e.errorMessage}`, e);
                            },
                        };
                        procentric.media.resume(param);
                    }, 40000);
                },
                onFailure(e) {
                    console.error(`Media pause error: ${e.errorMessage}`, e);
                },
            };
            procentric.media.pause(param);
            procentric.cspeed = 0;
            procentric.isPaused = true;
        } else {
            console.debug("Media variable is null. Not pausing.");
        }
    },

    /**
     * The callback function should look like this:
     * <code> function(param) {
     *				log.debug("media videoAvailable : " + param.videoAvailable);
     *				log.debug("media title : " + param.title);
     *				log.debug("media contentLengthInMs : " + param.contentLengthInMs);
     *			}
     *	</code>
     * @param callback The callback is a function with one parameter.
     */
    getMediaInfo() {
        if (procentric.media) {
            let informationParam = {
                onSuccess(param) {
                    //console.debug("Media videoAvailable : " + param);
                    if (param.videoAvailable) {
                        console.debug(`Media videoAvailable : ${param.videoAvailable}`);
                    }
                    if (param.title) {
                        console.debug(`Media title : ${param.title}`);
                    }
                    if (param.contentLengthInMs) {
                        procentric.mediaLength = param.contentLengthInMs;
                        console.debug(`Media contentLengthInMs : ${procentric.mediaLength}`);
                    }
                },
                onFailure(e) {
                    console.error(`Media getInfo error: ${e.errorMessage}`, e);
                },
            };
            procentric.media.getInformation(informationParam);
        } else {
            console.debug("Media variable is null. Not getting info.");
        }
    },

    getMediaLength() {
        if (procentric.mediaLength && procentric.mediaLength > 0) {
            return procentric.mediaLength;
        }
        procentric.getMediaInfo();
    },

    getMediaPlayPosition() {
        if (procentric.media) {
            let positionParam = {
                onSuccess(param) {
                    //console.debug("Play position : " + param.positionInMs);
                    if (param.positionInMs == procentric.mediaPosition && !procentric.isPaused) {
                        procentric.timesInPosition++;
                    } else {
                        procentric.timesInPosition = 0;
                    }
                    if (procentric.timesInPosition == 100) {
                        console.debug("Remains on same position. End video.");
                        procentric.timesInPosition = 0;

                        //limpiar interval que va pidiendo la posicion del video
                        if (Media.Video.positionInterval) {
                            clearInterval(Media.Video.positionInterval);
                        }

                        //quitar loading si existe
                        let loadingDiv = document.getElementById("loadingDiv");
                        if (loadingDiv) {
                            loadingDiv.parentNode.removeChild(loadingDiv);
                        }

                        // eslint-disable-next-line no-undef
                        dispatchNewMediaEvent(EVENTS.MEDIA.STATUS_END_OF_STREAM);
                        return;
                    }
                    procentric.mediaPosition = param.positionInMs;
                    //if(callback){callback(param.positionInMs);}

                    //remove movie loading page
                    let loadingDiv = document.getElementById("loadingDiv");
                    if (param.positionInMs > 0 && loadingDiv != undefined) {
                        //clearInterval(procentric.loadingInterval);
                        loadingDiv.parentNode.removeChild(loadingDiv);
                        //get audio list
                        setTimeout(() => {
                            procentric.getAudioList(); // falla en la LY750
                        }, 5000);
                    }
                },
                onFailure(e) {
                    console.error(`Media getPlayPosition error: ${e.errorMessage}`, e);
                },
            };
            procentric.media.getPlayPosition(positionParam);
        } else {
            procentric.mediaPosition = 0;
            console.debug("Media variable is null. Not getting play position.");
            //limpiar interval que va pidiendo la posicion del video
            if (Media.Video.positionInterval) {
                clearInterval(Media.Video.positionInterval);
            }
        }
    },

    setMediaPlayPosition(millis) {
        clearTimeout(procentric.playPosTimeout);
        if (procentric.media) {
            let positionParam = {
                positionInMs: Number(millis),
                onSuccess() {
                    console.debug("SET MEDIA PLAY POSITION SUCCESS");
                    //si no se fuerza un pause y resume despues de un seek,
                    // el seek no se completa y la imagen se queda freezeada
                    //procentric.playPosTimeout=procentric.continueMedia();
                },
                onFailure(e) {
                    console.error(`Media setPlayPosition error: ${e.errorMessage}`, e);
                },
            };
            procentric.media.setPlayPosition(positionParam);
        } else {
            procentric.mediaPosition = 0;
            console.debug("Media variable is null. Not setting play position.");
        }
    },

    setMediaPlaySpeed(speed) {
        if (procentric.media) {
            let speedParam = {
                speed,
                onSuccess() {
                    console.debug(`Play speed set to: ${speed}`);
                    procentric.cspeed = speed;
                },
                onFailure(e) {
                    console.error(`Media setSpeed error: ${e.errorMessage}`, e);
                },
            };
            procentric.media.setPlaySpeed(speedParam);
        } else {
            console.debug("Media variable is null. Not setting play speed.");
        }
    },

    getCurrentAudio() {
        hcap.channel.getCurrentChannelAudioLanguageIndex({
            onSuccess(s) {
                procentric.currentAudioInd = s.index;
                console.log(`current Audio : index = ${s.index}`);
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    setCurrentAudio(audioIndex) {
        if (procentric.currentChannelType != "") {
            hcap.channel.setCurrentChannelAudioLanguageIndex({
                index: audioIndex,
                onSuccess() {
                    procentric.currentAudioInd = audioIndex;
                    console.debug(`Current Audio changed to : Index: ${audioIndex}`);
                },
                onFailure(f) {
                    console.debug(`onFailure : errorMessage = ${f.errorMessage}`);
                },
            });
        } else {
            // setAudioLanguage still not working for media objet
            procentric.media.setAudioLanguage({
                index: audioIndex,
                onSuccess() {
                    procentric.currentAudioInd = audioIndex;
                    console.debug(`Current Audio changed to : Index: ${audioIndex}`);
                },
                onFailure(f) {
                    console.debug(`onFailure : errorMessage = ${f.errorMessage}`);
                },
            });
        }
    },
    actionsOnTuning() {
        console.debug("ACTIONS ON TUNING");
        procentric.getAudioList();
        procentric.getSubtitleList();
        procentric.getCurrentAudio();
        procentric.getCurrentSubtitle();

        if (procentric.currentChannelType != "") {
            hcap.channel.getCurrentChannelAudioLanguageList({
                onSuccess(s) {
                    procentric.audioList = s.list;
                    console.log(`onSuccess : list = ${s.list}`);
                    let audioLangs = s.list.split(",");
                    //SET GUEST AUDIO IF POSIBLE
                    for (let i = 0; i < audioLangs.length; i++) {
                        // eslint-disable-next-line no-undef
                        if (audioLangs[i] == localStorage.getItem("lang")) {
                            procentric.setCurrentAudio(i);
                            break;
                        }
                    }
                },
                onFailure(f) {
                    console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                },
            });
        }
    },

    getAudioList(onSuccess) {
        if (procentric.currentChannelType != "") {
            console.debug("Audio object channel");
            hcap.channel.getCurrentChannelAudioLanguageList({
                onSuccess(s) {
                    if (onSuccess) {
                        onSuccess(s.list);
                    }
                    procentric.audioList = s.list;
                    console.log(`onSuccess : list = ${s.list}`);
                },
                onFailure(f) {
                    if (onSuccess) {
                        onSuccess();
                    }
                    console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                },
            });
        } else {
            // getAudioLanguage still not working for media objet
            // eslint-disable-next-line no-lonely-if

            console.debug("Audio object media");
            procentric.media.getAudioLanguage({
                onSuccess(s) {
                    if (onSuccess) {
                        onSuccess(s.list);
                    }
                    procentric.audioList = s.list;
                    console.log(`onSuccess : list = ${s.list}`);
                },
                onFailure(f) {
                    if (onSuccess) {
                        onSuccess();
                    }
                    console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                },
            });
        }
    },

    getCurrentSubtitle() {
        hcap.channel.getCurrentChannelSubtitleIndex({
            onSuccess(s) {
                procentric.currentSubtitleInd = s.index;
                console.debug(`current Subtitle : index = ${s.index}`);
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    setCurrentSubtitle(subtitleIndex) {
        hcap.channel.setCurrentChannelSubtitleIndex({
            index: subtitleIndex,
            onSuccess() {
                procentric.currentSubtitleInd = subtitleIndex;
                console.debug(`Current Subtitle changed to : Index: ${subtitleIndex}`);
            },
            onFailure(f) {
                /*if(procentric.subtitleList!=null){
			     		procentric.setCurrentSubtitle(0);
			     	}*/
                console.debug(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },
    getSubtitleList(onSuccess) {
        hcap.channel.getCurrentChannelSubtitleList({
            onSuccess(s) {
                if (onSuccess) {
                    onSuccess(s.list);
                }
                procentric.subtitleList = s.list;
                console.log(`onSuccess : list = ${s.list}`);
            },
            onFailure(f) {
                if (onSuccess) {
                    onSuccess();
                }
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },
    setSubtitleUrl(subtitleIndex) {
        procentric.media.setSubtitleUrl({
            subtitleUrl: this.subtitleList[subtitleIndex].url_file,
            onSuccess() {
                procentric.currentSubtitleInd = Number(subtitleIndex) + 1;
            },
            onFailure(f) {
                console.log(`onFailure : error setting subtitle URL = ${f.errorMessage}`);
            },
        });
    },
    setSubtitleOn() {
        procentric.media.setSubtitleOn({
            subtitleOn: true,
            onSuccess() {},
            onFailure(f) {
                console.log(`onFailure : error setting subtitle On = ${f.errorMessage}`);
            },
        });
    },

    waitMediaState(state, callback) {
        //TODO better use a mediaStateChangeListener or something like that
        procentric.waitMediaStateInterval = setInterval(() => {
            procentric.getMediaState((cst) => {
                if (cst == state) {
                    clearInterval(procentric.waitMediaStateInterval);
                    callback(cst);
                }
            });
        }, 300);
    },

    getMediaState(callback) {
        let stateParam = {
            onSuccess(p) {
                if (typeof callback != "undefined") {
                    callback(p.state);
                }
            },
            onFailure(e) {
                console.error(`Failed to get media state: ${e.errorMessage}`, e);
            },
        };
        procentric.media.getState(stateParam);
    },

    onMediaEvent(param) {
        console.log(`event type = ${param.eventType}`);

        if (param.eventType == "play_end") {
            Media.playingMedia = false;
            dispatchNewMediaEvent(EVENTS.MEDIA.STATUS_END_OF_STREAM);
        } else if (param.eventType == "play_start") {
            Media.playingMedia = true;
            dispatchNewMediaEvent(EVENTS.MEDIA.PLAY_SUCCESS);
            //this trick is to get correct size playing trailers in LY750H model
            //is uset "resetVideoSize", because if the "setVideoSize" is used, the global
            //variable isFullScreen take wrong values
            if (procentric.lastVideoSize.x != undefined) {
                procentric.resetVideoSize(
                    procentric.lastVideoSize.x,
                    procentric.lastVideoSize.y,
                    procentric.lastVideoSize.width,
                    procentric.lastVideoSize.height,
                );
            }
        } else if (param.eventType == "file_not_found") {
            Media.playingMedia = false;
            dispatchNewMediaEvent(EVENTS.MEDIA.PLAY_ERROR);
        }
    },

    ///////////////////////////////////////////////////////////////////////////
    ///		VIDEO UTILS														///
    ///////////////////////////////////////////////////////////////////////////

    setVideoMute(bool) {
        let param = {
            videoMute: bool,
            onSuccess() {
                if (bool) {
                    console.debug("Video muted.");
                } else {
                    console.debug("Video unmuted.");
                }
            },
            onFailure(e) {
                console.error(`setVideoMute error: ${e.errorMessage}`, e);
            },
        };
        hcap.video.setVideoMute(param);
    },

    getVideoSize() {
        hcap.video.getVideoSize({
            onSuccess(s) {
                console.log(`${"onSuccess : \n x = "}${s.x}\n y = ${s.y}\n width = ${s.width}\n height = ${s.height}`);
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },
    setVideoSize(posx, posy, width, height) {
        //este intervalo asegura que se vuelva a fijar el tamaño del video en caso
        //de fallo
        /*  clearInterval(this.setVideoSizeInterval);
        this.setVideoSizeInterval = setInterval(function () {
            procentric.setVideoSize(posx, posy, width, height);
        }, 2000);*/

        this.lastVideoSize = {
            x: posx,
            y: posy,
            width,
            height,
        };

        Media.VIDEOPANE.style.top = `${posy}px`;
        Media.VIDEOPANE.style.left = `${posx}px`;
        Media.VIDEOPANE.style.width = `${width}px`;
        Media.VIDEOPANE.style.height = `${height}px`;
        Media.VIDEOPANE.style.visibility = "";

        let param = {
            x: posx,
            y: posy,
            width,
            height,
            onSuccess() {
                /*  clearInterval(procentric.setVideoSizeInterval);
                console.debug(
                    "Video size set to: (" +
                        posx +
                        "," +
                        posy +
                        "," +
                        width +
                        "," +
                        height +
                        ")"
                );
                if (width == sizes.SCREEN_WIDTH) {
                    procentric.isFullScreen = true;
                } else {
                    procentric.isFullScreen = false;
                }*/
            },
            onFailure(e) {
                console.log(`fallo resize: ${e.errorMessage}`);
                console.error(`setVideoSize error: ${e.errorMessage}`, e);
            },
        };
        hcap.video.setVideoSize(param);
    },

    setAspectRatio(aspectRatio) {
        hcap.property.setPictureProperty({
            key: hcap.property.PicturePropertyKey.ASPECT_RATIO,
            value: Number(aspectRatio),
            onSuccess() {
                console.debug(`aspectRatio - onSuccess changed to: ${aspectRatio}`);
            },
            onFailure(f) {
                console.debug(`aspectRatio - onFailure : errorMessage = ${f.errorMessage}`);
            },
        });
    },

    resetVideoSize(posx, posy, width, height) {
        let param = {
            x: posx,
            y: posy,
            width,
            height,
            onSuccess() {
                console.debug(`Reset video size set to: (${posx},${posy},${width},${height})`);
            },
            onFailure(e) {
                console.error(`ResetVideoSize error: ${e.errorMessage}`, e);
            },
        };
        hcap.video.setVideoSize(param);
    },

    ///////////////////////////////////////////////////////////////////////
    ///		OTHER UTILS													///
    ///////////////////////////////////////////////////////////////////////

    getStatus(onLoaded) {
        procentric.status = null;
        hcap.power.getPowerMode({
            onSuccess(s) {
                localStorage.setItem(STORAGE_KEY.POWER_STATE, s.mode == 1 ? STATE.ONLINE : STATE.STANDBY);
                if (onLoaded) {
                    if (s.mode == 1) {
                        onLoaded("online");
                    } else {
                        onLoaded("OFF");
                    }
                }
                procentric.status = s.mode;
            },
            onFailure(f) {
                console.log(`onFailure : errorMessage = ${f.errorMessage}`);
                procentric.status = 1;
            },
        });
    },
    poweron() {
        let param = {
            mode: hcap.power.PowerMode.NORMAL,
            onSuccess() {},
            onFailure(e) {
                console.error(`Error powering ON TV: ${e.errorMessage}`, e);
            },
        };
        hcap.power.setPowerMode(param);
    },
    poweroff() {
        let param = {
            onSuccess() {},
            onFailure(e) {
                console.error(`Error powering OFF TV: ${e.errorMessage}`, e);
            },
        };
        hcap.power.powerOff(param);
    },

    reboot() {
        let param = {
            onSuccess() {},
            onFailure(e) {
                console.error(`Error rebooting TV: ${e.errorMessage}`, e);
            },
        };
        hcap.power.reboot(param);
    },

    strToHex(str) {
        if (!str) {
            return;
        }
        let hex = "";
        for (let i = 0; i < str.length; i++) {
            let np = str.charCodeAt(i).toString(16);
            if (np.length == 1) {
                np = `0${np}`;
            }
            hex += np.toUpperCase();
        }
        return hex;
    },

    registerApps() {
        //NETFLIX
        hcap.application.RegisterSIApplicationList({
            tokenList: [{ id: "netflix", token: this.NETFLIX_TOKEN }],
            onSuccess: function (s) {
                console.log("onSuccess RegisterSIApplicationList: Netflix registered!");
            },
            onFailure: function (f) {
                console.log("onFailure RegisterSIApplicationList: reason = " + f.errorMessage);
            },
        });
    },

    registerAirserver() {
        //localStorage.removeItem("airServerRegistered");
        //AirServer
        if (!localStorage.getItem("airServerRegistered")) {
            document.addEventListener("idcap::conditioncertificate_register_status", (res) => {
                Logger.remoteLog(`CONDITIONCERT: ${res.status}- RetVal: ${JSON.stringify(res)}`);
                if (res.status === "success") {
                    const sMsg = "AirServer registered";
                    Logger.remoteLog(sMsg);
                    localStorage.setItem("airServerRegistered", true);
                    let e = new CustomEvent(EVENTS.type.POPUP, {
                        detail: { type: EVENTS.POPUP.CUSTOM_TEXT, text: sMsg },
                    });
                    document.dispatchEvent(e);
                    setTimeout(function () {
                        procentric.startAirServer();
                    }, 5000);
                } else if (res.status === "failed") {
                    const eMsg = "AirServer registration failed";
                    Logger.remoteLog(eMsg);
                    let e = new CustomEvent(EVENTS.type.POPUP, {
                        detail: { type: EVENTS.POPUP.CUSTOM_TEXT, text: eMsg },
                    });
                    document.dispatchEvent(e);
                }
            });
            setTimeout(function () {
                idcap.request("idcap://security/conditioncertificate/register", {
                    parameters: { accountNumber: sessionStorage.getItem("airserverAccountCode") },
                    onSuccess: function (cbObject) {
                        Logger.remoteLog(
                            "Register AirServer with code: " + sessionStorage.getItem("airserverAccountCode"),
                        );
                    },
                    onFailure: function (err) {
                        Logger.remoteLog("Register AirServer Failure : errorMessage = " + err.errorMessage);
                    },
                });
            }, 1000);
        } else {
            this.startAirServer();
        }
    },

    startAirServer() {
        console.log("start airserver");
        idcap.request("idcap://application/launch", {
            parameters: {
                id: "com.webos.app.commercial.airserver.launcher",
                params: { command: "start" },
            },
            onSuccess: function () {
                Logger.remoteLog("Airserver-start!");
            },
            onFailure: function (err) {
                Logger.remoteLog("Airserver Failed! reason : " + err.errorMessage);
            },
        });
    },

    checkOut(callback) {
        if (procentric.idcapSupport) {
            idcap.request("idcap://tv/checkout/request", {
                onSuccess: function () {
                    console.log("checkout succes");
                    if (typeof callback != "undefined") {
                        callback();
                    }
                },
                onFailure: function (f) {
                    console.log("checkout error : errorMessage = " + f.errorMessage);
                },
            });
        } else {
            hcap.checkout.requestCheckout({
                onSuccess: function () {
                    console.log("checkout succes");
                    if (typeof callback != "undefined") {
                        callback();
                    }
                },
                onFailure: function (f) {
                    console.log("checkout error : errorMessage = " + f.errorMessage);
                },
            });
        }
    },

    prepareUDPCommunication() {
        document.addEventListener(
            "idcap::udp_data_received",
            function (param) {
                console.log(param?.data);

                switch (param?.data?.trim()) {
                    case "ccInformation":
                        console.log("ccInformation request");
                        procentric.sendDeviceInfoToCastingGW();
                        break;
                    default:
                        break;
                }
            },
            false,
        );

        idcap.request("idcap://network/udpdaemon/open", {
            parameters: {
                port: this.UDP_PORT_IN,
            },
            onSuccess: function () {
                console.log("puerto-abierto");
            },
            onFailure: function (err) {
                console.log("puerto-onFailure : errorMessage = " + err.errorMessage);
            },
        });
    },

    sendDeviceInfoToCastingGW() {
        var deviceInfo = {
            model: localStorage.getItem("tvModel"),
            mac: STB.mac,
            ref: STB.ref,
        };
        console.log(JSON.stringify(deviceInfo));
        idcap.request("idcap://network/udpdata/send", {
            parameters: {
                ip: sessionStorage.getItem("castingGatewayIP"),
                port: this.UDP_PORT_OUT,
                udpData: JSON.stringify(deviceInfo),
            },
            onSuccess: function () {
                console.log("onSuccess");
            },
            onFailure: function (err) {
                console.log("onFailure : errorMessage = " + err.errorMessage);
            },
        });
    },
};
