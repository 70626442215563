import React from "react";
import Button from "../../../components/common/button";
import { Media } from "../../../hooks/apis/media";
import { figmapx2vh, figmapx2vw } from "../../../utils/utils";
import { MOVIE_DETAIL_DISPLAY } from "../../../utils/constants";
import ReactHtmlParser from "react-html-parser";

const TopBar = ({ movie, backToMovieDetail }) => {
    return (
        <div
            style={{
                width: "100vw",
                height: figmapx2vh(164),
                background: "linear-gradient(to bottom, #373D42, transparent)",
                padding: `${figmapx2vw(50)} ${figmapx2vh(60)}`,
                color: "#F5F6F8",
            }}
        >
            <div className={"float-left"} style={{ width: "50%" }}>
                <Button
                    data={{
                        id: "movie-back",
                        icon: "tv-back-remote",
                        iconSize: "text-4xl",
                        customClass: "float-left text-center mr-6",
                        customStyle: {
                            width: figmapx2vw(64),
                            height: figmapx2vw(64),
                            borderRadius: "50%",
                            color: "#F5F6F8",
                            backgroundColor: "transparent",
                        },

                        focusClass: "grid-btn-back-focus",
                        onClick() {
                            backToMovieDetail();
                        },
                    }}
                />
                <Button
                    data={{
                        id: "start-over",
                        icon: "start-over",
                        iconSize: "text-4xl",
                        customClass: "float-left text-center",
                        customStyle: {
                            width: figmapx2vw(64),
                            height: figmapx2vw(64),
                            borderRadius: "50%",
                            color: "#F5F6F8",
                            backgroundColor: "transparent",
                        },

                        focusClass: "grid-btn-back-focus",
                        onClick() {
                            Media.setStreamPos(0);
                        },
                    }}
                />
            </div>
            <div className={"text-right float-left"} style={{ width: "50%", lineHeight: "5vh" }}>
                {ReactHtmlParser(movie.name)}
            </div>
        </div>
    );
};

export default TopBar;
