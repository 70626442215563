import { Logger } from "../../../utils/logger";
import { movie } from "../../../utils/movies";
import Query from "../../query";

export const sendDisplayTime = (input: {
    movie: movie;
    stayGuestRoomId: number;
    startTime: string;
    visualizationTime: number;
    lastMovieTime: number;
}) => {
    const percentageReached = Math.round((input.lastMovieTime * 100) / (input.movie.length * 60));
    if (!input.startTime || !input.visualizationTime) {
        Logger.remoteLog("Movie visualization data not enought");
        return;
    }

    Query({
        query: `
                mutation{ 
                    registerMovieVisualization (
                        movieId:${input.movie.id}, 
                        stayGuestRoomId:${input.stayGuestRoomId} , 
                        startTime:"${input.startTime}" ,
                        visualizationTime:${input.visualizationTime}, 
                        percentageReached:${percentageReached || 0}
                        
                ) 
                    {
                        error 
                            {
                                code 
                                msg
                            } 
                        ok 
                    }
                }
        `,
        onResponse(res) {
            if (res?.data?.registerMovieVisualization?.ok) {
            } else {
                Logger.remoteLog("Error registering movie visualization times");
            }
        },
    });
};
