import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/common/button";
import { Texts } from "../../utils/texts";
import { useEffect, useState } from "preact/hooks";
import focus from "../../utils/focus";
import { figmapx2vh, figmapx2vw, getDasExternalUrl, getStayGuestRoomId, inPreview, vh2px } from "../../utils/utils";
import { isMovieFullyWatched, printLanguages, printMovieLength, printRentaToText } from "../../utils/movies";
import { useLayoutEffect } from "react";
import { eventHandler } from "../../utils/eventHandler";
import { KEYS } from "../../utils/keys";
import { MOVIE_DETAIL_DISPLAY, PERMISSION } from "../../utils/constants";
import Trailer from "./trailer";
import Player from "./player";
import { displayErrorPopUp, setPopUp } from "../../actions/uiActions";
import { SESSION } from "../../utils/session";
import { getRentedMovies, updateMovieTimes } from "../../hooks/GraphqlCalls/movies/getRentedMovies";
import { rentMovie } from "../../hooks/GraphqlCalls/movies/rentMovie";
import TrailerButton from "./detail/trailerButton";
import RentButton from "./detail/rentButton";
import StartOverButton from "./detail/startOverButton";
import FullPlotDisplay, { RenderReadMoreButton } from "./detail/fullPlotDisplay";
import Loading from "../../components/loading";
import ReactHtmlParser from "react-html-parser";

const MAX_HEIGHT_PLOT = vh2px(15.323529411764705) + 2;

const MovieDetail = ({ item = null, itemUpdate = null }) => {
    const dispatch = useDispatch();

    const texts = useSelector((state) => state.ui.texts);
    const CURRENCY_CODE = useSelector((state) => state.status.locationData.projectCurrency);
    const { sessionData } = useSelector((state) => state.status);
    const popup = useSelector((state) => state.ui.popup);

    const [refreshMovieData, setRefreshMovieData] = useState(false);
    const [itemUpdated, setItemUpdated] = useState(item);
    const [readMorePlotButton, setReadMorePlotButton] = useState(false);
    const [showMorePlot, setShowMorePlot] = useState(false);
    const [detailStatusDisplay, setDetailStatusDisplay] = useState(MOVIE_DETAIL_DISPLAY.DETAIL);
    const [loading, setSetLoading] = useState(true);

    const stayGuestRoomId = getStayGuestRoomId(sessionData);
    const FREE2GUEST = SESSION.hasPermission(PERMISSION.FREE_TO_GUEST);

    useEffect(() => {
        setRefreshMovieData(true);
    }, []);

    useLayoutEffect(() => {
        if (!loading && document.getElementById("hiddenPlot")?.getBoundingClientRect().height > MAX_HEIGHT_PLOT) {
            setReadMorePlotButton(true);
        }
    }, [loading]);

    useEffect(() => {
        if (refreshMovieData) {
            setSetLoading(true);
            updateMovieTimes({ stayGuestRoomId, last24h: !FREE2GUEST, movie: item }, (movieTimes) => {
                setItemUpdated(movieTimes);
                itemUpdate(movieTimes, ["rented", "rentedAt", "visualizations"]);
                setRefreshMovieData(false);
                setSetLoading(false);
                if (SESSION.playMovie) {
                    SESSION.playMovie = false;
                    playMovie();
                } else if (detailStatusDisplay === MOVIE_DETAIL_DISPLAY.DETAIL) {
                    focus.value.replace("btn-rent");
                }
            });
        }
    }, [refreshMovieData]);

    useEffect(() => {
        if (SESSION.refreshMovieData) {
            SESSION.refreshMovieData = false;
            setRefreshMovieData(true);
        }
    }, [SESSION.refreshMovieData]);

    const playTrailer = () => {
        setDetailStatusDisplay(MOVIE_DETAIL_DISPLAY.TRAILER);
    };

    const playMovie = () => {
        setDetailStatusDisplay(MOVIE_DETAIL_DISPLAY.PLAYER);
    };

    const startOverMovie = () => {
        setItemUpdated({ ...itemUpdated, visualizations: [] });
        playMovie();
    };

    const displayFullPlot = () => {
        setDetailStatusDisplay(MOVIE_DETAIL_DISPLAY.MORE_PLOT);
    };

    const backToMovieDetail = () => {
        setDetailStatusDisplay(MOVIE_DETAIL_DISPLAY.DETAIL);
    };

    useLayoutEffect(() => {
        if (detailStatusDisplay === MOVIE_DETAIL_DISPLAY.MORE_PLOT && document.getElementById("btn-fullPlot")) {
            focus.value.replace("btn-fullPlot");
        } else if (detailStatusDisplay === MOVIE_DETAIL_DISPLAY.DETAIL && document.getElementById("btn-rent")) {
            focus.value.replace("btn-rent");
        }
    }, [detailStatusDisplay]);

    const renderMovieDetail = () => {
        return (
            <>
                <div
                    className={"text-gray-100"}
                    style={{ width: figmapx2vw(904), fontSize: figmapx2vw(24), marginBottom: figmapx2vh(32) }}
                >
                    <div className={"font-700"} style={{ fontSize: figmapx2vw(40), marginBottom: figmapx2vh(24) }}>
                        {ReactHtmlParser(item?.name)}
                    </div>
                    <div className={"text-gray-300 font-400"} style={{ marginBottom: figmapx2vh(40) }}>
                        {`${item?.year ? item?.year : ""} ${item.groupName} - ${printMovieLength(item.length)}`}
                    </div>
                    <div
                        id="plot"
                        style={{
                            fontSize: figmapx2vw(28),
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            webkitLineClamp: 5,
                            webkitBoxOrient: "vertical",
                        }}
                    >
                        {ReactHtmlParser(item.plot)}
                    </div>
                    <RenderReadMoreButton readMorePlotButton={readMorePlotButton} displayFullPlot={displayFullPlot} />
                    <div style={{ marginBottom: figmapx2vh(32) }}>{`${Texts.translateCap(texts, "director")}: ${
                        item.director
                    }`}</div>
                    <div
                        style={{
                            marginBottom: figmapx2vh(64),
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            webkitLineClamp: 2,
                            webkitBoxOrient: "vertical",
                        }}
                    >{`${Texts.translateCap(texts, "cast")}: ${item.actors}`}</div>
                    <div class={"inline-flex"} style={{ marginBottom: figmapx2vh(24) }}>
                        <RentButton item={item} itemUpdated={itemUpdated} playMovie={playMovie} />
                        <TrailerButton item={item} playTrailer={playTrailer} />
                        <StartOverButton itemUpdated={itemUpdated} startOverMovie={startOverMovie} />
                    </div>
                    <div style={{ marginBottom: figmapx2vh(64) }}>
                        {!FREE2GUEST || item.isAdult
                            ? itemUpdated?.rented
                                ? printRentaToText(texts, itemUpdated?.rentedAt)
                                : Texts.translate(texts, "watch-time-24h")
                            : null}
                    </div>
                    {!item.isAdult && (
                        <>
                            <div className={"font-700"}>{Texts.translateCap(texts, "audio")}</div>
                            <div style={{ marginBottom: figmapx2vh(32) }}>{printLanguages(item.languages, texts)}</div>
                            {/* <div className={"font-700"}>{Texts.translateCap(texts, "subtitles")}</div>
                            <div>{printLanguages(item.subtitles, texts)}</div>
                            */}
                        </>
                    )}
                </div>
            </>
        );
    };

    const onMovieDetailKeydown = (e) => {
        const keyData = eventHandler.getKeyData(e);
        let preventDefault = false;

        if (keyData) {
            if (keyData.type === "numeric") {
                eventHandler.stopPropagation(e);
                return;
            }
            switch (keyData.value) {
                case KEYS.back:
                    //BACK FROM ITEM DETAIL
                    if (detailStatusDisplay !== MOVIE_DETAIL_DISPLAY.DETAIL) {
                        setDetailStatusDisplay(MOVIE_DETAIL_DISPLAY.DETAIL);
                        preventDefault = true;
                    }
                    break;
                default:
                    break;
            }
        }

        if (preventDefault) {
            eventHandler.stopPropagation(e);
        }
    };

    return (
        <div
            id="movieDetailWrapper"
            onKeyDown={(e) => {
                onMovieDetailKeydown(e);
            }}
        >
            {loading ? (
                <div className="fixed w-full h-full z-100">
                    <Loading
                        style={{
                            color: "white",
                            fontSize: "1.87305vw",

                            zIndex: 100,
                        }}
                    />
                </div>
            ) : detailStatusDisplay === MOVIE_DETAIL_DISPLAY.TRAILER ? (
                <Trailer movie={item} backToMovieDetail={backToMovieDetail} />
            ) : detailStatusDisplay === MOVIE_DETAIL_DISPLAY.PLAYER ? (
                <Player
                    movie={itemUpdated}
                    backToMovieDetail={backToMovieDetail}
                    setRefreshMovieData={setRefreshMovieData}
                />
            ) : (
                <>
                    <div
                        className={"w-full h-full absolute bg-cover bg-no-repeat bg-center"}
                        style={{
                            let: 0,
                            top: 0,
                            background: `url(${getDasExternalUrl(item.backgroundUrl, {
                                w: window.innerWidth,
                                h: window.innerHeight,
                            })})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            zIndex: 3,
                        }}
                    ></div>
                    <div
                        className={"w-full h-full absolute"}
                        style={{
                            top: 0,
                            left: 0,
                            paddingLeft: figmapx2vw(72),
                            paddingTop: figmapx2vh(72),
                            paddingBottom: figmapx2vh(72),
                            background:
                                "linear-gradient(90deg, #292929 0%, rgba(41, 41, 41, 0.8) 38%, rgba(41, 41, 41, 0) 81%)",
                            zIndex: 5,
                        }}
                    >
                        {detailStatusDisplay === MOVIE_DETAIL_DISPLAY.MORE_PLOT ? (
                            <FullPlotDisplay plot={item.plot} />
                        ) : (
                            renderMovieDetail()
                        )}
                    </div>
                    <div
                        id="hiddenPlot"
                        style={{
                            position: "absolute",
                            top: window.innerHeight,
                            width: figmapx2vw(904),
                            fontSize: figmapx2vw(28),
                            marginBottom: figmapx2vh(32),
                            visibility: "hidden",
                        }}
                    >
                        {ReactHtmlParser(item.plot)}
                    </div>
                </>
            )}
        </div>
    );
};

export default MovieDetail;
